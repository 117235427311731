import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  TextField,
  Grid,
  Button,
  Stack,
  IconButton,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MainCard from "../MainCard";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { useFetchCountryListQuery } from "../../redux/api/Users/getCountryList";
import { useFetchIndustryListQuery } from "../../redux/api/Industry/getIndustry";
import { useFetchUseCaseListQuery } from "../../redux/api/usecase/getUseCase";
import { toast } from "react-toastify";
import { useFetchMasterDetailsQuery } from "../../redux/api/Users/master";
import CustomLoader from "../../utils/Loader/CustomLoader";
import { useFetchBusinessUserDetailsQuery } from "../../redux/api/Users/getuserProfile";
import { useDispatch, useSelector } from "react-redux";
import Flag from "react-world-flags";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useUpdateBusinessUserProfileMutation } from "../../redux/api/Users/updateBusinessProfile";
import AvatarWithUpload from "./customAvatar";
import { getImages } from "../const";
import shortid from "shortid";

const updateBusinessProfile = ({ nextStep, showToastMessage }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const storedUserType = localStorage.getItem("userType");
  // If storedUserType is null or "null", set businessUserType to "business_user"
  const userId = localStorage.getItem("userId");
  // api call userProfileData
  const {
    data: userProfileData,
    isLoading: userLoading,
    isError: userError,
    refetch,
  } = useFetchBusinessUserDetailsQuery(userId);
  const { data } = userProfileData || {};
  const user = data?.user_id || {};

  // Business Logo
  const [avatar , setAvatar] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState([]);
    // Update handle function to capture the selected file
    const handleFileChange = (e) => {
      const newFiles = Array.from(e.target.files); // Get the selected file
      setAvatar(newFiles); // Update the state with the selected file
      setSelectedAvatar((prevFiles) => [
        ...prevFiles,
        ...newFiles.map((file) => ({
          id: shortid.generate(),
          filename: file.name,
          filetype: file.type,
          fileimage: URL.createObjectURL(file),
          datetime: file.lastModifiedDate.toLocaleString("en-IN"),
          filesize: filesizes(file.size),
        })),
      ]);
    };
    const filesizes = (bytes, decimals = 2) => {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const getInitials = (name) => {
      const namesArray = name.split(" ");
      const firstInitial = namesArray[0]?.charAt(0).toUpperCase();
      const secondInitial = namesArray[1] ? namesArray[1].charAt(0).toUpperCase() : "";
      return firstInitial + secondInitial;
    };
  
    // Get the preview URL of the latest selected avatar, if any
    const previewImage =
      selectedAvatar.length > 0
        ? selectedAvatar[selectedAvatar.length - 1].fileimage
        : data?.business_logo || getImages("companyLogo.jfif");

  // businessName
  const [businessName, setBusinessName] = useState(data?.business_name || "");
  const handleBusinessName = (event) => {
    const inputValue = event.target.value.trim();
    setBusinessName(inputValue);
  };
  // businessUrl
  const [businessUrl, setBusinessUrl] = useState(data?.business_url || "");
  const handleBusinessUrl = (event) => {
    const inputValue = event.target.value.trim();
    setBusinessUrl(inputValue);
  };

  // LinkedinUrl
  const [linkedinUrl, setLinkedinUrl] = useState(data?.linkedin_url || "");
  const handleLinkedinUrl = (event) => {
    const inputValue = event.target.value.trim();
    setLinkedinUrl(inputValue);
  };

  // countryCode
  const [countryCode, setCountryCode] = useState(
    data?.country_id?.phone_code || ""
  );
  const handleCountryCode = (event) => {
    const inputValue = event.target.value.trim();
    setCountryCode(inputValue);
  };
  // phoneNumber
  const [phoneNumber, setPhoneNumber] = useState(data?.phone_number || "");
  const handlePhoneNumber = (event) => {
    const inputValue = event.target.value.trim();
    setPhoneNumber(inputValue);
  };
  // Role
  const [role, setRole] = useState(data?.company_role || "");
  const handleRole = (event) => {
    const inputValue = event.target.value.trim();
    setRole(inputValue);
  };
  // Head Country
  const [headCountry, setHeadCountry] = useState(data?.country_id?.name || "");
  const [headCountryId, setHeadCountryId] = useState(
    data?.country_id?._id || ""
  );

  const {
    data: countryData,
    isLoading: countryLoading,
    isError: countryError,
  } = useFetchCountryListQuery();

  const handleHeadCountry = (event) => {
    const selectedCountry = event.target.value.trim();
    if (selectedCountry === "") {
      setHeadCountry(data?.country_id?.name || "");
      setHeadCountryId(countryData?.country_id?._id || "");
    } else {
      setHeadCountry(selectedCountry);
      const selectedCountryObject = countryData?.data.find(
        (country) => country.name === selectedCountry
      );
      if (selectedCountryObject) {
        setHeadCountryId(selectedCountryObject._id);
      } else {
        setHeadCountryId("");
      }
    }
  };

  // Industry
  const [industry, setIndustry] = useState(data?.industry_type || "");
  const handleIndustry = (event) => {
    const inputValue = event.target.value.trim();
    setIndustry(inputValue);
  };
  // Tech
  const [tech, setTech] = useState(data?.tech_type || "");
  // const handleTech = (event) => {
  //   const inputValue = event.target.value.trim();
  //   setTech(inputValue);
  // };
  // UseCase
  const [useCase, setUseCase] = useState(data?.usecase_type || "");
  // const handleUseCase = (event) => {
  //   const inputValue = event.target.value.trim();
  //   setUseCase(inputValue);
  // };
  // Company Size
  const [companySize, setCompanySize] = useState(data?.company_size || "");

  const handleCompanySize = (event) => {
    const inputValue = event.target.value.trim();
    setCompanySize(inputValue);
  };
  // Company Revenue
  const [companyRevenue, setCompanyRevenue] = useState(
    data?.company_revenue_level || ""
  );
  const handleCompanyRevenue = (event) => {
    const inputValue = event.target.value.trim();
    setCompanyRevenue(inputValue);
  };

  const [
    updateBusinessUserProfile,
    { isLoading: profileUpdateLoading, isError: profileError },
  ] = useUpdateBusinessUserProfileMutation();

  const {
    data: IndustryData,
    isLoading: industryLoading,
    error: industryError,
  } = useFetchIndustryListQuery();
  const {
    data: UseCaseData,
    isLoading: useCaseLoading,
    error: useCaseError,
  } = useFetchUseCaseListQuery();
  const {
    data: masterData,
    isLoading: masterLoading,
    error: masterError,
  } = useFetchMasterDetailsQuery();

  const updateProfile = async () => {
    const formData = new FormData();
   if(avatar && avatar.length > 0){
    avatar.forEach((file) => formData.append("business_logo", file));
   }

   // Append other user details to FormData
   formData.append("user_id", userId);
   formData.append("user_type", storedUserType);
   formData.append("business_name", businessName !== "" ? businessName : data?.business_name);
   formData.append("business_url",  businessUrl !== "" ? businessUrl : data?.business_url);
   formData.append("phone_code", countryCode !== "" ? countryCode : data?.country_id?.phone_code);
   formData.append("phone_number", phoneNumber !== "" ? phoneNumber : data?.phone_number);
   formData.append("country_id", headCountryId !== "" ? headCountryId : data?.country_id._id);
   formData.append("tech_type", tech !== "" ? tech : tech);
   formData.append("industry_type", industry !== "" ? industry : data?.industry_type);
   formData.append("usecase_type", useCase !== "" ? useCase : data?.usecase_type);
   formData.append("company_role", role !== "" ? role : data?.company_role);
   formData.append("company_size", companySize !== "" ? companySize : data?.company_size);
   formData.append("company_revenue_level", companyRevenue !== "" ? companyRevenue : data?.company_revenue_level);
   formData.append("linkedin_url", linkedinUrl);

    try {
      const response = await updateBusinessUserProfile({
        profileId: userProfileData?.data._id,
        formData,
      }).unwrap();
      const successMessage = response?.message || "Profile updated";
      showToastMessage(successMessage, "success");
      if(response?.statusCode === 500){
        showToastMessage(successMessage, "error");
      }
    } catch (error) {
      const errorMessage = error?.message || "Failed to update";
      showToastMessage(errorMessage, "error");
    }
  };
  useEffect(() => {
    if (data) {
      setBusinessName(data.business_name || "");
      setBusinessUrl(data?.business_url || "");
      setPhoneNumber(data?.phone_number || "");
      setPhoneNumber(data?.phone_number || "");
      setLinkedinUrl(data?.linkedin_url || "");
    }
  }, [data]);

  useEffect(() => {
    if (profileUpdateLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [profileUpdateLoading, dispatch]);

  return (
    <>
      <Box
        sx={
          {
            // background: "#dbebf8",
          }
        }
      >
        <Box
          sx={{
            // pt: 3,
            maxWidth: "100%",
            margin: "0 auto",
            // mb: 3,
            [theme.breakpoints.up("xl")]: {
              maxWidth: "100vw",
              // marginLeft: "25vw",
            },
          }}
        >
          <MainCard
            content={false}
            sx={{
              backgroundColor: "#FFFFFF",
              border: "none",
              padding: "0",
              boxShadow: "none",
              borderRadius: "10px",
              p: 1,
              [theme.breakpoints.down("md")]: {
                p: 0,
              },
            }}
          >
            <Grid
              container
              direction={{ xs: "column", md: "row" , lg: "row" , xl: "row" }}
              rowSpacing={2}
              columnSpacing={3}
              sx={{
                mb: 2,
              }}
            >
                 {/* Business Logo */}
                 <Grid item xs={2} sm={3} md={ open ? 4 : 3} lg={open? 3: 2} xl={2}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%", 
                    justifyContent: 'flex-start',
                    [theme.breakpoints.down("md")]: {
                      // justifyContent: 'center'
                    },
                  }}
                >
                  <AvatarWithUpload previewImage={previewImage} handleFileChange={handleFileChange}  />
                </Box>
              </Grid>
              {/* Business Name and Business Url in parallel */}
              <Grid item xs={12} sm={9} md={open? 8 : 9} lg={open? 9: 10}  xl={10}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {/* Business Name */}
                  <Box>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "secondary.main",
                        fontWeight:'bold',
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Business Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={businessName}
                      placeholder="Business Name*"
                      required
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "#DBEBF8",
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                      onChange={handleBusinessName}
                    />
                  </Box>
                  {/* Business URL */}
                  <Box>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "secondary.main",
                        fontWeight:'bold',
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Business Url
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={businessUrl}
                      placeholder="Business Website*"
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "#DBEBF8",
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                      onChange={handleBusinessUrl}
                    />
                  </Box>
                  
                </Box>
              </Grid>
            </Grid>

            <Grid
              key={data?._id}
              container
              rowSpacing={2}
              columnSpacing={3}
              sx={{
                mb: 2,
              }}
            >
              {/* Country code and phoneNumber */}
              <Grid item xs={12} md={6} lg={6} xl={12}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={2}
                  sx={{
                    mb: 0,
                  }}
                >
                  {/* Label for Select */}
                  <Grid item xs={4} md={3} lg={3}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "secondary.main",
                        fontWeight:'bold',
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Country Code
                    </Typography>
                    {countryData?.data && (
                      <FormControl
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={countryCode}
                          onChange={handleCountryCode}
                          displayEmpty
                          IconComponent={KeyboardArrowDownOutlinedIcon}
                          sx={{
                            background: "#ECF6FF",
                            "& .MuiSelect-select": {
                              minHeight: "20px",
                              padding: "11px 14px",
                              color: "lightSecondary.main",
                              fontWeight: "600",
                              fontSize: "0.9rem",
                              border: "solid 1px #DBEBF8",
                            },
                            "& .MuiSelect-icon": {
                              color: "lightSecondary.main",
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: "200px",
                                overflowY: "auto",
                                "&::-webkit-scrollbar": {
                                  width: "6px",
                                  borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-track": {
                                  backgroundColor: "transparent",
                                  borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#9C9C9C",
                                  borderRadius: "4px",
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem value={countryCode} disabled>
                            {data?.country_id?.phone_code || "ph-code*"}
                          </MenuItem>
                          {countryData &&
                            countryData.data.map((country) => (
                              <MenuItem
                                key={country._id}
                                value={country.phone_code}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Flag
                                    code={country.iso2}
                                    style={{
                                      width: "20px",
                                      height: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  {country.phone_code}
                                </Box>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>

                  {/* Label for Phone Number */}
                  <Grid item xs={8} md={9} lg={9}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "secondary.main",
                        fontWeight:'bold',
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={phoneNumber}
                      placeholder="phoneNumber"
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "#DBEBF8",
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                      onChange={handlePhoneNumber}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Role */}
              <Grid item xs={12} md={6} lg={6} xl={12}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight:'bold',
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Role
                </Typography>

                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role}
                    onChange={handleRole}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={role} disabled>
                      {data?.company_role || "Select Role*"}
                    </MenuItem>
                    {masterData?.masterData?.roles.map((role, index) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Country */}
              <Grid item xs={12} md={6} lg={6} xl={12}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight:'bold',
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Country
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={headCountry}
                    onChange={handleHeadCountry}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={headCountry} disabled>
                      {data?.country_id?.name || "Select Country*"}
                    </MenuItem>
                    {countryData &&
                      countryData.data.map((country) => (
                        <MenuItem key={country._id} value={country.name}>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Flag
                              code={country.iso2}
                              style={{
                                width: "20px",
                                height: "15px",
                                marginRight: "10px",
                              }}
                            />
                            {country.name}
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Industry */}
              <Grid item xs={12} md={6} lg={6} xl={12}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight:'bold',
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Industry
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={industry}
                    onChange={handleIndustry}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={industry} disabled>
                      {data?.industry_type || "Select Industry*"}
                    </MenuItem>
                    {IndustryData &&
                      IndustryData.data.map((industry) => (
                        <MenuItem key={industry._id} value={industry.name}>
                          {industry.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* company size */}
              <Grid item xs={12} md={6} lg={6} xl={12}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight:'bold',
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Company Size
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={companySize}
                    onChange={handleCompanySize}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={companySize} disabled>
                      {data?.company_size || "Select Company Size*"}
                    </MenuItem>
                    {masterData?.masterData?.companySize.map(
                      (values, index) =>
                        index === index && (
                          <MenuItem key={values} value={values}>
                            {values}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* company Revenue */}
              <Grid item xs={12} md={6} lg={6} xl={12}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight:'bold',
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Company Revenue
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={companyRevenue}
                    onChange={handleCompanyRevenue}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={companyRevenue} disabled>
                      {data?.company_revenue_level || "Select Company Revenue*"}
                    </MenuItem>
                    {masterData?.masterData?.companyRevenue.map(
                      (revenue, index) =>
                        index === index && (
                          <MenuItem key={revenue} value={revenue}>
                            {revenue}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* Linkedin url */}
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight:'bold',
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Linkedin Url
                </Typography>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  variant="filled"
                  value={linkedinUrl}
                  placeholder="Linkedin Url"
                  style={{
                    margin: 0,
                    width: "100%",
                  }}
                  sx={{
                    width: "100%",
                    borderRadius: "4px",
                    backgroundColor: "extraLTheme.main",
                    border: "solid 1px #DBEBF8",
                    m: 0,
                    "& .MuiInputBase-root": {
                      backgroundColor: "extraLTheme.main",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "0.9rem",
                      color: "lightSecondary.main",
                      fontWeight: "600",
                      pt: 1.5,
                      pb: 1.5,
                      backgroundColor: "extraLTheme.main",
                    },
                    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                      {
                        border: "none",
                      },
                    "& .MuiInputBase-root:hover:before": {
                      border: "none",
                    },
                    "&:hover": {
                      borderColor: "#DBEBF8",
                      backgroundColor: "extraLTheme.main",
                    },
                    "& .MuiInputBase-root:before": {
                      border: "none",
                    },
                    "& .MuiInputBase-root:after": {
                      border: "none",
                    },
                  }}
                  onChange={handleLinkedinUrl}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "inherit",
                  p: "10px 20px",
                  boxShadow: "none",
                  fontWeight: "600",
                  mt: 4,
                  width: "240px",
                }}
                onClick={updateProfile}
              >
                Save Changes
              </Button>
            </Box>
          </MainCard>
        </Box>
      </Box>
    </>
  );
};

export default updateBusinessProfile;
