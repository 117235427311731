import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  Badge,
  Button,
  IconButton,
  TextField,
  styled,
  Stack,
  AvatarGroup,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { getImages } from "../../commonComponents/const";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import { UserAddOutlined } from "@ant-design/icons";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CloseOutlined } from "@mui/icons-material";
import {
  setChatSelected,
  setOpenGroupChat,
  toggleOpenChatInfo,
  toggleOpenNewChat,
  toggleOpenSolutionList,
  toggleThreadChat,
} from "../../redux/features/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { useCreateGroupMutation } from "../../redux/api/Inbox/createGroup";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useSendMessageMutation } from "../../redux/api/Inbox/sendMessage";
import { toast } from "react-toastify";
import { useFetchGetMessageQuery } from "../../redux/api/Inbox/getMessage";
import socket from "./socket";
import Modals from "../Modals";
import {
  setRefetchBusinessUserList,
  setRefetchSolutionList,
} from "../../redux/features/refetch";
import SendDocument from "./sendDocument";
import ManageUsers from "./manageUser";
import MessageDisplay from "./messageDisplay";
import shortid from "shortid";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFetchMessageReadQuery } from "../../redux/api/Inbox/messageRead";
import CustomLoader from "../../utils/Loader/CustomLoader";
import { hideChatLoader, showChatLoader } from "../../redux/features/chatLoadingSLice";
import ChatBoxLoader from "../../utils/Loader/chatBoxLoader";

const GroupChatContainer = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const messageBoxRef = useRef(null);
  const [groupCreated, setGroupCreated] = useState(false);

  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const userName = localStorage.getItem("userName");
  const userPic = localStorage.getItem("avatar");
  const solutionList = useSelector((state) => state.chat.openSolutionList);
  const chatLoaderVisible = useSelector((state) => state.chatLoadingSlice.visible);
  // const groupChatId = useSelector((state) => state.chat.groupChatId);
  const {
    solutionProviderId,
    solutionId,
    businessUserId,
    solutionName,
    businessName,
    businessLogo,
    solutionLogo,
    groupName,
    groupChatId,
    
  } = useSelector((state) => state.chat);
  const open = useSelector((state) => state.drawer.open);
  const [selectedUserForChat, setSelectUserForChat] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const [sentMessage, setSentMessage] = useState(null);
  const [receiveMessages, setReceiveMessages] = useState([]);
  const [currentGroupId, setCurrentGroupId] = useState();
  const [groupData, setGroupData] = useState([]);
  const [messageArray, setMessageArray] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 50;
  const [previousHeight, setPreviousHeight] = useState(0);
  const [imagePreview , setImagePreview] = useState(false);
  const [imageUrl , setImageUrl] = useState("");

  const handleImagePreview = (imageUrl)=>{
    setImagePreview(true)
    setImageUrl(imageUrl);
  };
  const handleImagePreviewClosed = ()=>{
    setImagePreview(false)
  };
  
  // api for message reading

  // const [
  //   readMessage,
  //   { isLoading: isMessageRead, isError: messageReadError },
  // ] = useMessageReadMutation();
  const {
    data: messageRead, // Data returned from the GET API call
    error: messageReadError, // Error information if the call fails
    isLoading: isMessageLoading, // Loading state for the API call
    refetch: refetchReadMessage, // Function to manually refetch the data
  } = useFetchMessageReadQuery({
    userId, // Pass userId (required)
    groupChatId: groupChatId, // Initially, we don't have a message ID to fetch
  });
  // Listen for incoming messages
  useEffect(() => {
    const messageHandler = (message) => {
      setMessageArray((prevMessages) => [...prevMessages, message]);
      refetchReadMessage({
        userId: userId,
        groupChatId: message?.chat?._id,
      }).unwrap();
    };
    socket.on("message received", messageHandler);
    return () => {
      socket.off("message received", messageHandler);
    };
  }, []);

  //   Download logic
  const handleDownload = async (documentUrl, documentName) => {
    try {
      // Fetch the file from the server
      const response = await fetch(documentUrl);

      // Check if the response is ok
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a blob from the response
      const blob = await response.blob();

      // Create a link element
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      // Set the href of the link to the blob URL
      link.href = url;

      // Choose the filename for the downloaded file
      link.download = documentName || "downloaded-file"; // Ensure documentName is a string

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up and remove the link from the DOM
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Free up memory
      }, 100);
    } catch (error) {
      showToastMessage("download failed", "success");
    }
  };

  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const date = new Date(sentMessage?.createdAt);
  const formattedTime = date.toLocaleTimeString("en-US", options);

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  const chatInfoHandle = () => {
    dispatch(toggleOpenChatInfo());
    if (!solutionList) {
      dispatch(toggleOpenSolutionList());
    }
  };
  const threadChatHandle = () => {
    dispatch(toggleThreadChat());
  };
  const closeChatContainer = () => {
    dispatch(setChatSelected(false));
    dispatch(setOpenGroupChat(false));
  };

  //   upload modal
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const handleOpenUploadModal = () => {
    setOpenUploadModal(true);
  };
  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    setSelectedFiles([]);
    setFiles([]);
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);

    // If no files were selected, return early and don't open modal
    if (newFiles.length === 0) {
      return;
    }

    setFiles(newFiles);
    setSelectedFiles((prevFiles) => [
      ...prevFiles,
      ...newFiles.map((file) => ({
        id: shortid.generate(),
        filename: file.name,
        filetype: file.type,
        fileimage: URL.createObjectURL(file),
        datetime: file.lastModifiedDate.toLocaleString("en-IN"),
        filesize: filesizes(file.size),
      })),
    ]);

    // Open modal after selecting files
    handleOpenUploadModal();
  };

  //   Manage users modal
  const [openManageUsers, setOpenManegeUsers] = useState(false);
  const handleOpenManageUsers = () => {
    setOpenManegeUsers(true);
  };
  const handleCloseUManageUsers = () => {
    setOpenManegeUsers(false);
  };

  const [
    sendMessages,
    { isLoading: sendMessageLoading, isError: sendMessageError },
  ] = useSendMessageMutation();

  const {
    data: messageData,
    error: messageDataError,
    isLoading: messageDataLoading,
    refetch: refetchMessages,
  } = useFetchGetMessageQuery({
    groupChatId,
    userId,
    page,
    limit,
  });

  // Handle room change based on groupId
  useEffect(() => {
    if (currentGroupId) {
      socket.emit("leave chat", currentGroupId); // Leave the current room when groupId changes
      setMessageArray([]);
      // setPage(1);
      setHasMore(true);
      setUserInput("");
    }

    if (groupChatId) {
      setCurrentGroupId(groupChatId); // Store the new groupChatId
      socket.emit("join chat", [groupChatId, userId]); // Join the new group
      refetchMessages();
    }
  }, [groupChatId]);

  useEffect(() => {
    if (messageData) {
      setGroupData(messageData?.data?.existingChat); // Set groupData when messages are available
      //  setMessageArray(messageData?.data?.getGroupMessageData)
    }
  }, [messageData]);

  useEffect(() => {
    if (messageData && messageData.data.getGroupMessageData) {
      // If there are fewer messages than the limit, no more messages are available
      if (messageData?.data?.getGroupMessageData?.length < limit) {
        setHasMore(false);
      }

      // Prepend new messages to the messageArray
      setMessageArray((prevMessages) => [
        ...messageData?.data?.getGroupMessageData,
        ...prevMessages,
      ]);
    }
  }, [messageData]);
  // Function to handle scroll up for pagination
  const handleInfiniteLoad = () => {
    if (hasMore && messageBoxRef.current.scrollTop === 0) {
      dispatch(showLoader()); // Show loader when fetching more messages
      setPage((prevPage) => prevPage + 1);
      refetchMessages().finally(() => {
        dispatch(hideLoader()); // Hide loader after fetching is done
      });
    }
  };

  
  // Pushing receive message in groupData
  // useEffect(() => {
  //   if (receiveMessages && receiveMessages.length > 0) {
  //     setGroupData((prevGroupData) => {
  //       const existingMessages = prevGroupData?.data?.getGroupMessageData || [];
  //       // Map the received messages to the desired format
  //       const newMessages = receiveMessages.map((message) => {
  //         if (message.is_file === "Yes") {
  //           return {
  //             chat: message.chat._id,
  //             createdAt: message.createdAt,
  //             message: message.message,
  //             sender: {
  //               _id: message.sender._id,
  //               name: message?.sender?.name,
  //               pic: message?.sender?.pic || "",
  //             },
  //             is_file: "Yes",
  //             drive_ids: message.drive_ids.map((drive) => ({
  //               createdAt: drive.createdAt || new Date().toISOString(),
  //               isDeleted: drive.isDeleted,
  //               isDocument: drive.isDocument,
  //               name: drive.name || "", // Handling if the name is missing
  //               owner_id: drive.owner_id,
  //               parent_id: drive.parent_id || "null", // Default to "null" if no parent_id
  //               s3Url: drive.s3Url || "", // Default to empty string if no URL
  //               updatedAt: drive.updatedAt || new Date().toISOString(),
  //               __v: drive.__v || 0,
  //               _id: drive._id, // Unique document ID
  //             })),
  //             updatedAt: message.updatedAt,
  //             _id: message._id,
  //           };
  //         } else {
  //           return {
  //             chat: message.chat._id,
  //             createdAt: message.createdAt,
  //             message: message.message,
  //             sender: {
  //               _id: message.sender._id,
  //               name: message?.sender?.name,
  //               pic: message?.sender?.pic || "",
  //             },
  //             is_file: "No",
  //             updatedAt: message.updatedAt,
  //             _id: message._id,
  //           };
  //         }
  //       });
  //       return {
  //         ...prevGroupData,
  //         data: {
  //           ...prevGroupData?.data,
  //           getGroupMessageData: [
  //             ...existingMessages, // Keep the existing messages
  //             ...newMessages, // Add the new messages received via socket
  //           ],
  //         },
  //       };
  //     });
  //   }
  //   // refetchMessages();
  //   // if(userType === "solution_provider"){
  //   //   dispatch(setRefetchBusinessUserList(true));
  //   // }else{
  //   //   dispatch(setRefetchSolutionList(true));
  //   // }
  // }, [receiveMessages]);

  // useEffect(() => {
  //   if (
  //     userId &&
  //     businessUserId &&
  //     solutionName &&
  //     solutionProviderId &&
  //     solutionId
  //   ) {
  //     createGroupChat();
  //     dispatch(showLoader());
  //   } else {
  //     dispatch(hideLoader());
  //   }
  // }, [userId, solutionName, solutionProviderId, businessUserId, solutionId]);

  // const createGroupChat = async () => {
  //   try {
  //     const response = await createGroup({
  //       userId: userId,
  //       groupName: solutionName,
  //       solutionProviderId: solutionProviderId,
  //       businessUserId: businessUserId,
  //       solutionId: solutionId,
  //     }).unwrap();
  //     dispatch(showLoader());
  //     setGroupData(response?.data);
  //     setChatId(response?.data?.existingChat?._id);
  //     socket.emit("join chat", response?.data?.existingChat._id);
  //     setGroupCreated(true);
  //   } catch (error) {
  //   } finally {
  //     // Hide the loader when the API call is finished, regardless of the outcome
  //     dispatch(hideLoader());
  //   }
  // };

  const handleMessage = async () => {
    // Trim the userInput to remove leading and trailing spaces
    if (!userInput.trim()) {
      return; // Exit the function if the input is empty or only contains whitespace
    }
    try {
      const response = await sendMessages({
        userId: userId,
        message: userInput,
        groupId: groupData?._id,
      }).unwrap();
      // Emit the message via Socket.IO
      // Construct the new message object
      const newMessageRec = {
        sender: {
          _id: userId,
          name: userName, // Replace with the actual user's name
          avatar: userPic || "", // Replace with actual user's picture URL
        },
        chat: {
          _id: groupData?._id,
          chatName: groupData?.chatName,
          isGroupChat: groupData?.isGroupChat || true,
          users: groupData?.users.map((user) => user._id),
        },
        is_file: "No",
        message: userInput,
        _id: response?.data?._id, // Replace with message ID from response
        createdAt: response?.data?.createdAt || new Date().toISOString(),
        updatedAt: response?.data?.updatedAt || new Date().toISOString(),
      };
      // Update groupData by adding the new message to the beginning of getGroupMessageData
      // setGroupData((prevGroupData) => {
      //   const existingMessages = prevGroupData?.data?.getGroupMessageData || [];

      //   return {
      //     ...prevGroupData,
      //     data: {
      //       ...prevGroupData?.data,
      //       getGroupMessageData: [
      //         ...existingMessages, // Keep existing messages
      //         newMessageRec, // Add the newly sent message
      //       ],
      //     },
      //   };
      // });
      socket.emit("new message", newMessageRec);
      setUserInput("");
      setPage(1)
      // refetchMessages();
      if(userType === "solution_provider"){
        dispatch(setRefetchBusinessUserList(true));
      }else{
        dispatch(setRefetchSolutionList(true));
      }
    } catch (error) {
      //   showToastMessage("Failed to schedule meeting", "error");
    } finally {
      // Hide the loader when the API call is finished, regardless of the outcome
      dispatch(hideLoader());
    }
  };

  // // for scroll up when message sent
  // useEffect(() => {
  //   if (messageData) {
  //     if (messageBoxRef.current) {
  //       const prevScrollHeight = messageBoxRef.current.scrollHeight;
  //       const newMessagesLength = messageData.data.getGroupMessageData.length;

  //       // Prepend new messages
  //       setMessageArray(prevMessages => [
  //         ...messageData.data.getGroupMessageData,
  //         ...prevMessages,
  //       ]);

  //       // Adjust scroll position after new messages are added
  //       setTimeout(() => {
  //         if (messageBoxRef.current) {
  //           messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight - prevScrollHeight;
  //         }
  //       }, 100); // Adjust timeout as needed
  //     }
  //   }
  // }, [messageData]);

  // const debounce = (func, delay) => {
  //   let timeoutId;
  //   return (...args) => {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //     timeoutId = setTimeout(() => {
  //       func.apply(null, args);
  //     }, delay);
  //   };
  // };

  // const handleScroll = (() => {
  //   if (messageBoxRef.current.scrollTop === 0) {
  //     handleInfiniteLoad();
  //   }
  // }, 300);

  // useEffect(()=>{
  //   if (messageBoxRef.current.scrollTop === 0) {
  //     handleInfiniteLoad();
  //   }
  // })
  useEffect(() => {
    if (messageBoxRef.current && page == 1) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight; 
      setPreviousHeight(messageBoxRef.current.scrollHeight)
    }else{
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight - previousHeight
    }
    setPreviousHeight(messageBoxRef.current.scrollHeight)
  }, [messageArray]);

  useEffect(() => {
    if (messageDataLoading) {
      dispatch(showChatLoader());
    } else {
      dispatch(hideChatLoader());
    }
  }, [messageDataLoading, groupChatId]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        {/* Chat header */}
        {groupData && (
          <Box
            sx={{
              backgroundColor: "extraLTheme.main",
              borderRadius: "10px 10px 0 0",
              p: 1.2,
              display: "flex",
              alignItems: "center",
            }}
            key={groupData?._id}
          >
            {businessLogo ? (
              <Avatar
                sx={{
                  color: "#fff",
                  bgcolor:
                    businessLogo === ""
                      ? "lightSecondary.main"
                      : "success.lighter",
                  width: "50px",
                  height: "50px",
                  maxWidth: "50px",
                  mr: 1,
                }}
                src={businessLogo}
              >
                {businessLogo && businessLogo !== ""
                  ? businessLogo
                  : groupName?.substring(0, 2)?.toUpperCase()}
              </Avatar>
            ) : (
              <Box>
                {solutionLogo && solutionLogo !== "" ? (
                  <img
                    style={{
                      minWidth: "80px",
                      maxWidth: "106px",
                      minHeight: "20px",
                      maxHeight: "30px",
                      mixBlendMode: "multiply",
                    }}
                    src={solutionLogo}
                    alt={`${groupName?.charAt(0) || ""}`}
                  />
                ) : (
                  <Avatar
                    sx={{
                      color: "#fff",
                      bgcolor: "lightSecondary.main",
                      width: "50px",
                      height: "50px",
                      maxWidth: "50px",
                      mr: 1,
                    }}
                  >
                    {groupName?.substring(0, 2)?.toUpperCase()}
                  </Avatar>
                )}
              </Box>
            )}

            <ListItemText
              sx={{
                pl: 0.5,
                width: "auto",
              }}
              primary={
                <Typography
                  noWrap
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "secondary.main",
                    lineHeight: "22px",
                    maxWidth: "220px",
                    [theme.breakpoints.down("sm")]: {
                      maxWidth: "110px",
                    },
                  }}
                  variant="h6"
                >
                  {groupName
                    ? `${groupName.charAt(0).toUpperCase()}${groupName.slice(
                        1
                      )}`
                    : ""}
                </Typography>
              }
              secondary={
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "primary.main",
                    pl: 0,
                  }}
                >
                  {[...(groupData?.users || [])] // Create a shallow copy of the array
                    .slice() // Ensure the array is mutable by creating a new copy
                    .sort((a, b) =>
                      a._id === userId ? 1 : b._id === userId ? -1 : 0
                    ) // Sort to move the matching userId to the end
                    .map((user) => user.first_name)
                    .join(" , ")}
                </Typography>
              }
            />
            <ListItemText
              sx={{
                width: "calc(40% - 56px)",
                textAlign: "right",
                [theme.breakpoints.down("md")]: {
                  whiteSpace: "nowrap",
                  minWidth: "120px",
                },
              }}
            >
              <IconButton
                sx={{
                  position: "relative",
                  [theme.breakpoints.down("lg")]: {
                    px: 0.5,
                  },
                }}
                onClick={handleOpenManageUsers}
              >
                <UserAddOutlined
                  style={{
                    width: "22px",
                    height: "22px",
                    color: "#2A589C",
                  }}
                />
              </IconButton>
              {/* <IconButton
                sx={{
                  position: "relative",
                  [theme.breakpoints.down("lg")]: {
                    px: 0.7,
                  },
                }}
              >
                <CalendarMonthIcon
                  sx={{
                    color: "lightSecondary.main",
                    fontSize: "24px",
                  }}
                />
                <LocalPhoneIcon
                  sx={{
                    color: "lightSecondary.main",
                    fontSize: "17px",
                    position: "absolute",
                    left: "4px",
                    bottom: "2px",
                  }}
                />
              </IconButton> */}
              {/* <IconButton
                onClick={chatInfoHandle}
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    px: 0.5,
                  },
                }}
              >
                <InfoOutlinedIcon
                  sx={{
                    color: "lightSecondary.main",
                    fontSize: "24px",
                  }}
                />
              </IconButton> */}
              {/* <IconButton
                onClick={closeChatContainer}
                sx={{
                  [theme.breakpoints.up("lg")]: {
                    display: "none",
                  },
                  [theme.breakpoints.down("lg")]: {
                    px: 0.2,
                  },
                }}
              >
                <CloseOutlined
                  sx={{
                    color: "lightSecondary.main",
                    fontSize: "24px",
                  }}
                />
              </IconButton> */}
            </ListItemText>
          </Box>
        )}
       
        {chatLoaderVisible && <ChatBoxLoader />}
        {/* Chat Body */}
        <Box
          id="scrollableDiv"
          ref={messageBoxRef}
          onScroll={handleInfiniteLoad}
          sx={{
            position: "relative",
            overflowY: "auto",
            overflowX: "hidden",
            pr: 1,
            maxHeight: "calc(100vh - 265px)",
            minHeight: "calc(100vh - 267px)",
            "&::-webkit-scrollbar": {
              width: "6px",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#9C9C9C",
              borderRadius: "4px",
            },
            [theme.breakpoints.down("lg")]: {
              maxHeight: "calc(100vh - 224px)",
              minHeight: "calc(100vh - 226px)",
            },
            [theme.breakpoints.down("md")]: {
              // maxHeight: "calc(100vh - 251px)",
              minHeight: "100vh",
            },
          }}
        >
          <InfiniteScroll
            dataLength={messageArray.length}
            // next={handleInfiniteLoad}
            hasMore={hasMore}
            inverse={true}
            scrollableTarget="scrollableDiv"
            // style={{ display: 'flex', flexDirection: 'column' }}
          >
            {messageArray && Array.isArray(messageArray) && (
              <>
                {messageArray.map((message, index) => (
                  <Box key={message._id} sx={{ py: 2 }}>
                    {message.sender._id === userId ? (
                      // Sender message
                      <Box
                        sx={{
                          textAlign: "right",
                          pr: 2,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          gap: "3vh",
                          // width: "100%",
                        }}
                      >
                        {/* New */}
                        {message?.is_file === "No" ? (
                          <Box
                            sx={{
                              backgroundColor: "lightSecondary.main",
                              borderRadius: "10px 0px 10px 10px",
                              p: "10px 15px",
                              maxWidth: "45%",
                              position: "relative",
                              textAlign: "left",
                              "&:before": {
                                content: '""',
                                position: "absolute",
                                right: "-16px",
                                top: "0",
                                borderLeft: "solid 16px #2A589C",
                                borderBottom: "solid 16px transparent",
                              },
                              [theme.breakpoints.down("lg")]: {
                                width: "50%",
                              },
                              [theme.breakpoints.down("md")]: {
                                width: "50%",
                              },
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "0.9rem",
                                color: "#fff",
                                fontWeight: "500",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              <MessageDisplay senderMessage={message.message} />
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                color: "#fff",
                                fontWeight: "500",
                                textAlign: "right",
                                marginTop: "6px",
                              }}
                            >
                              {`${new Date(
                                message?.createdAt
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })} \u00A0 ${new Date(
                                message?.createdAt
                              ).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })}`}
                            </Typography>
                          </Box>
                        ) : (
                          <>
                            {message?.drive_ids?.length === 1 ? (
                              // JSX to render if the condition is true
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                }}
                              >
                                {message?.drive_ids?.map((document, index) => {
                                  const fileExtension = document.name
                                    ? document.name
                                        .split(".")
                                        .pop()
                                        .toLowerCase()
                                    : "";
                                  const isImage = [
                                    "jpg",
                                    "jpeg",
                                    "png",
                                  ].includes(fileExtension);
                                  const isPDF = fileExtension === "pdf";
                                  const isGif = fileExtension === "gif";
                                  const isDocx = fileExtension === "docx";
                                  const isDoc = fileExtension === "doc";
                                  const isMp4 = fileExtension === "mp4";
                                  const isAvi = fileExtension === "avi";
                                  const isMov = fileExtension === "mov";
                                  const isWmv = fileExtension === "wmv";
                                  const isMp3 = fileExtension === "mp3";
                                  const isWav = fileExtension === "wav";
                                  const isOgg = fileExtension === "ogg";
                                  const xlsx = fileExtension === "xlsx";
                                  const csv = fileExtension === "csv";

                                  return isImage ? (
                                    <Box
                                      key={document._id}
                                      sx={{
                                        backgroundColor: "lightSecondary.main",
                                        borderRadius: "10px 0px 10px 10px",
                                        p: "10px 15px",
                                        maxWidth: "45%",
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "left",
                                        "&:before": {
                                          content: '""',
                                          position: "absolute",
                                          right: "-16px",
                                          top: "0",
                                          borderLeft: "solid 16px #2A589C",
                                          borderBottom:
                                            "solid 16px transparent",
                                        },
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          mt: 0.5,
                                          textAlign: "right",
                                          p: "5px 5px 5px 5px",
                                          borderRadius: '8px',
                                          overFlow:'hidden',
                                          position:'relative',
                                          cursor:'pointer'
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "140px",
                                            height: "11rem",
                                            borderRadius: "8px",
                                            objectFit: "contain",
                                          }}
                                          src={document.s3Url}
                                        />
                                           <Box
                                        tabIndex={-1}
                                        sx={{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          // width: "150px",
                                          // height: "11rem",
                                          p: "5px 5px 5px 5px",
                                          borderRadius: "8px",
                                          backgroundColor: 'rgba(0, 0, 0, 0)', // transparent by default
                                          display: 'flex',
                                          alignItems: 'flex-start',
                                          justifyContent: 'flex-end',
                                          mr:0,
                                          transition: "background-color 0.3s ease",
                                          cursor: "pointer", 
                                          '&:hover': {
                                          backgroundColor: 'rgba(0, 0, 0, 0.7)', 
                                          '& .download-icon': {
                                            opacity: 1, 
                                            visibility: 'visible',
                                          },
                                          '& .preview-icon': {
                                            opacity: 1, 
                                            visibility: 'visible',
                                          },
                                        },
                                        }}
                                      >
                                        <VisibilityIcon
                                        className="preview-icon"
                                        sx={{
                                          fontSize: 20,
                                          color: "white",
                                          opacity: 0, 
                                          visibility: 'hidden', 
                                          transition: "opacity 0.3s ease, visibility 0.3s ease", 
                                        }}
                                        onClick={() =>
                                          handleImagePreview(
                                            document.s3Url,
                                          )
                                        }
                                        />
                                          <FileDownloadOutlinedIcon
                                           className="download-icon"
                                            sx={{
                                              fontSize: 20,
                                              color: "white",
                                              opacity: 0, // initially hidden
                                              visibility: 'hidden', // initially not visible
                                              transition: "opacity 0.3s ease, visibility 0.3s ease", // transition for smooth appearance
                                            }}
                                            onClick={() =>
                                              handleDownload(
                                                document.s3Url,
                                                document.name
                                              )
                                            }
                                          />
                                      </Box>
                                      </Box>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "0.9rem",
                                          color: "#fff",
                                          fontWeight: "500",
                                          whiteSpace: "pre-wrap",
                                          overflowWrap: "break-word", // Ensure long words wrap correctly
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <MessageDisplay
                                          senderMessage={message.message}
                                        />
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "12px",
                                          color: "ltheme.main",
                                          fontWeight: "500",
                                          textAlign: "right",
                                        }}
                                      >
                                        {`${new Date(
                                          document?.createdAt
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })} \u00A0 ${new Date(
                                          document?.createdAt
                                        ).toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}`}
                                      </Typography>
                                    </Box>
                                  ) : (
                                    //  Document
                                    <Box
                                      key={document._id}
                                      sx={{
                                        backgroundColor: "lightSecondary.main",
                                        borderRadius: "10px 0px 10px 10px",
                                        p: "10px 15px",
                                        width: open ? "45%" : "45%",
                                        position: "relative",
                                        display: "inline-block",
                                        textAlign: "left",
                                        "&:before": {
                                          content: '""',
                                          position: "absolute",
                                          right: "-16px",
                                          top: "0",
                                          borderLeft: "solid 16px #2A589C",
                                          borderBottom:
                                            "solid 16px transparent",
                                        },

                                        [theme.breakpoints.down("lg")]: {
                                          width: "50%",
                                        },
                                        [theme.breakpoints.down("md")]: {
                                          width: "65%",
                                        },
                                      }}
                                    >
                                      <Stack
                                        direction="row"
                                        sx={{
                                          my: 0.5,
                                          // backgroundColor: '#DBEBF8',
                                          backgroundColor: "#fff",
                                          p: 2,
                                          borderRadius: "10px",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          sx={{
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "80%",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <ListItemAvatar
                                            sx={{
                                              textAlign: "center",
                                              backgroundColor: "#fff",
                                              width: "20%",
                                              minWidth: "50px",
                                              borderRadius: "10px",
                                              height: "50px",
                                              lineHeight: "50px",
                                              position: "relative",
                                            }}
                                          >
                                            <img
                                              style={{
                                                maxWidth: "40px",
                                                maxHeight: "40px",
                                                position: "absolute",
                                                left: "50%",
                                                top: "50%",
                                                transform:
                                                  "translate(-50%, -50%)",
                                              }}
                                              src={
                                                isPDF
                                                  ? getImages("pdf.png") // Replace with your PDF icon path
                                                  : isGif
                                                  ? getImages("file.png")
                                                  : isDocx
                                                  ? getImages("doc.png")
                                                  : isDoc
                                                  ? getImages("doc.png")
                                                  : isMp4
                                                  ? getImages("mp4.png")
                                                  : isAvi
                                                  ? getImages("file.png")
                                                  : isMov
                                                  ? getImages("file.png")
                                                  : isWav
                                                  ? getImages("file.png")
                                                  : isMp3
                                                  ? getImages("mp3.png")
                                                  : // : isWav
                                                  // ? getImages("wav.png")
                                                  isOgg
                                                  ? getImages("file.png")
                                                  : xlsx
                                                  ? getImages("xls.png")
                                                  : csv
                                                  ? getImages("csv.png")
                                                  : getImages("file.png") // Replace with your default icon path
                                              }
                                            />
                                          </ListItemAvatar>
                                          <Stack
                                            sx={{
                                              pl: 2,
                                              width: "80%",
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <Typography
                                              noWrap
                                              sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                color: "secondary.main",
                                                lineHeight: "22px",
                                                mb: 0.5,
                                              }}
                                              variant="subtitle1"
                                            >
                                              <Tooltip title={document.name}>
                                                {document.name}
                                              </Tooltip>
                                            </Typography>
                                            <Typography
                                              noWrap
                                              variant="body2"
                                              sx={{
                                                fontSize: "0.8rem",
                                                fontWeight: "500",
                                                color: "ltheme.main",
                                                width: "100%",
                                              }}
                                            >
                                              {fileExtension}
                                            </Typography>
                                          </Stack>
                                        </Stack>
                                        <IconButton
                                          sx={{
                                            width: "10%",
                                            maxWidth: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            backgroundColor: "transparent", // Ensure no background color by default
                                            "&:hover": {
                                              backgroundColor: "transparent", // Prevent background color change on hover
                                            },
                                          }}
                                          onClick={() =>
                                            handleDownload(
                                              document.s3Url,
                                              document.name
                                            )
                                          }
                                        >
                                          <FileDownloadOutlinedIcon
                                            sx={{
                                              color: "lightSecondary.main",
                                            }}
                                          />
                                        </IconButton>
                                      </Stack>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "0.9rem",
                                          color: "#fff",
                                          fontWeight: "500",
                                          whiteSpace: "pre-wrap",
                                          overflowWrap: "break-word", // Ensure long words wrap correctly
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <MessageDisplay
                                          senderMessage={message.message}
                                        />
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "12px",
                                          color: "ltheme.main",
                                          fontWeight: "500",
                                          textAlign: "right",
                                        }}
                                      >
                                        {`${new Date(
                                          document?.createdAt
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })} \u00A0 ${new Date(
                                          document?.createdAt
                                        ).toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}`}
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                            ) : (
                              // when multiple documents
                              <Box
                                sx={{
                                  backgroundColor: "lightSecondary.main",
                                  borderRadius: "10px 0px 10px 10px",
                                  p: "10px 15px",
                                  maxWidth: "45%",
                                  position: "relative",
                                  display: "inline-block",
                                  textAlign: "left",
                                  "&:before": {
                                    content: '""',
                                    position: "absolute",
                                    right: "-16px",
                                    top: "0",
                                    borderLeft: "solid 16px #2A589C",
                                    borderBottom: "solid 16px transparent",
                                  },

                                  [theme.breakpoints.down("lg")]: {
                                    width: "50%",
                                  },
                                  [theme.breakpoints.down("md")]: {
                                    width: "65%",
                                  },
                                }}
                              >
                                {message?.drive_ids?.map((document, index) => {
                                  const fileExtension = document.name
                                    ? document.name
                                        .split(".")
                                        .pop()
                                        .toLowerCase()
                                    : "";
                                  const isImage = [
                                    "jpg",
                                    "jpeg",
                                    "png",
                                  ].includes(fileExtension);
                                  const isPDF = fileExtension === "pdf";
                                  const isGif = fileExtension === "gif";
                                  const isDocx = fileExtension === "docx";
                                  const isDoc = fileExtension === "doc";
                                  const isMp4 = fileExtension === "mp4";
                                  const isAvi = fileExtension === "avi";
                                  const isMov = fileExtension === "mov";
                                  const isWmv = fileExtension === "wmv";
                                  const isMp3 = fileExtension === "mp3";
                                  const isWav = fileExtension === "wav";
                                  const isOgg = fileExtension === "ogg";
                                  const xlsx = fileExtension === "xlsx";
                                  const csv = fileExtension === "csv";

                                  return isImage ? (
                                    <Box key={document._id}>
                                      <Box
                                        sx={{
                                          mt: 0.5,
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          mb: 1,
                                          p: "5px 5px 5px 5px",
                                          overFlow:'hidden',
                                          position:'relative',
                                          cursor:'pointer'
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "140px",
                                            height: "11rem",
                                            borderRadius: "8px",
                                            objectFit: "contain",
                                          }}
                                          src={document.s3Url}
                                        />
                                         <Box
                                        tabIndex={-1}
                                        sx={{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          width: "150px",
                                          height: "11rem",
                                          p: "5px 5px 5px 5px",
                                          borderRadius: "8px",
                                          backgroundColor: 'rgba(0, 0, 0, 0)', // transparent by default
                                          display: 'flex',
                                          alignItems: 'flex-start',
                                          justifyContent: 'flex-end',
                                          mr:0,
                                          transition: "background-color 0.3s ease",
                                          cursor: "pointer", 
                                          '&:hover': {
                                          backgroundColor: 'rgba(0, 0, 0, 0.7)', 
                                          '& .download-icon': {
                                            opacity: 1, 
                                            visibility: 'visible',
                                          },
                                          '& .preview-icon': {
                                            opacity: 1, 
                                            visibility: 'visible',
                                          },
                                        },
                                        }}
                                      >
                                        <VisibilityIcon
                                        className="preview-icon"
                                        sx={{
                                          fontSize: 20,
                                          color: "white",
                                          opacity: 0, 
                                          visibility: 'hidden', 
                                          transition: "opacity 0.3s ease, visibility 0.3s ease", 
                                        }}
                                        onClick={() =>
                                          handleImagePreview(
                                            document.s3Url,
                                          )
                                        }
                                        />
                                          <FileDownloadOutlinedIcon
                                           className="download-icon"
                                            sx={{
                                              fontSize: 20,
                                              color: "white",
                                              opacity: 0, // initially hidden
                                              visibility: 'hidden', // initially not visible
                                              transition: "opacity 0.3s ease, visibility 0.3s ease", // transition for smooth appearance
                                            }}
                                            onClick={() =>
                                              handleDownload(
                                                document.s3Url,
                                                document.name
                                              )
                                            }
                                          />
                                      </Box>
                                      </Box>
                                    </Box>
                                  ) : (
                                    //  Document
                                    <Box key={document._id}>
                                      <Stack
                                        direction="row"
                                        sx={{
                                          my: 0.5,
                                          // backgroundColor: '#DBEBF8',
                                          backgroundColor: "#fff",
                                          p: 2,
                                          borderRadius: "10px",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          sx={{
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "80%",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <ListItemAvatar
                                            sx={{
                                              textAlign: "center",
                                              backgroundColor: "#fff",
                                              width: "20%",
                                              minWidth: "50px",
                                              borderRadius: "10px",
                                              height: "50px",
                                              lineHeight: "50px",
                                              position: "relative",
                                            }}
                                          >
                                            <img
                                              style={{
                                                maxWidth: "40px",
                                                maxHeight: "40px",
                                                position: "absolute",
                                                left: "50%",
                                                top: "50%",
                                                transform:
                                                  "translate(-50%, -50%)",
                                              }}
                                              src={
                                                isPDF
                                                  ? getImages("pdf.png") // Replace with your PDF icon path
                                                  : isGif
                                                  ? getImages("file.png")
                                                  : isDocx
                                                  ? getImages("doc.png")
                                                  : isDoc
                                                  ? getImages("doc.png")
                                                  : isMp4
                                                  ? getImages("mp4.png")
                                                  : isAvi
                                                  ? getImages("file.png")
                                                  : isMov
                                                  ? getImages("file.png")
                                                  : isWav
                                                  ? getImages("file.png")
                                                  : isMp3
                                                  ? getImages("mp3.png")
                                                  : // : isWav
                                                  // ? getImages("wav.png")
                                                  isOgg
                                                  ? getImages("file.png")
                                                  : xlsx
                                                  ? getImages("xls.png")
                                                  : csv
                                                  ? getImages("csv.png")
                                                  : getImages("file.png") // Replace with your default icon path
                                              }
                                            />
                                          </ListItemAvatar>
                                          <Stack
                                            sx={{
                                              pl: 2,
                                              width: "80%",
                                              maxWidth: "100%",
                                              textAlign: "left",
                                            }}
                                          >
                                            <Typography
                                              noWrap
                                              sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                color: "secondary.main",
                                                lineHeight: "22px",
                                                mb: 0.5,
                                              }}
                                              variant="subtitle1"
                                            >
                                              <Tooltip title={document.name}>
                                                {document.name}
                                              </Tooltip>
                                            </Typography>
                                            <Typography
                                              noWrap
                                              variant="body2"
                                              sx={{
                                                fontSize: "0.8rem",
                                                fontWeight: "500",
                                                color: "ltheme.main",
                                                width: "100%",
                                              }}
                                            >
                                              {fileExtension}
                                            </Typography>
                                          </Stack>
                                        </Stack>
                                        <IconButton
                                          sx={{
                                            width: "10%",
                                            maxWidth: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            backgroundColor: "transparent", // Ensure no background color by default
                                            "&:hover": {
                                              backgroundColor: "transparent", // Prevent background color change on hover
                                            },
                                          }}
                                          onClick={() =>
                                            handleDownload(
                                              document.s3Url,
                                              document.name
                                            )
                                          }
                                        >
                                          <FileDownloadOutlinedIcon
                                            sx={{
                                              color: "lightSecondary.main",
                                            }}
                                          />
                                        </IconButton>
                                      </Stack>
                                    </Box>
                                  );
                                })}
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "0.9rem",
                                    color: "#fff",
                                    fontWeight: "500",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "right",
                                  }}
                                >
                                  <MessageDisplay
                                    senderMessage={message.message}
                                  />
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "12px",
                                    color: "ltheme.main",
                                    fontWeight: "500",
                                    textAlign: "right",
                                  }}
                                >
                                  {`${new Date(
                                    message?.createdAt
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })} \u00A0 ${new Date(
                                    message?.createdAt
                                  ).toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  })}`}
                                </Typography>
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    ) : (
                      // Receiver message
                      <Box
                        sx={{
                          textAlign: "left",
                          pl: 2,
                          // py: 2,
                          display: "flex",
                          flexDirection: "column",
                          gap: "2vh",
                          // width: "100%",
                        }}
                      >
                        {message?.is_file === "No" ? (
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <ListItemAvatar
                              sx={{
                                width: "42px",
                              }}
                            >
                              <Avatar
                                sx={{
                                  color: "#fff",
                                  bgcolor: "lightSecondary.main",
                                  width: "40px",
                                  height: "40px",
                                }}
                                src={message?.sender?.avatar}
                              >
                                {!message?.sender?.avatar &&
                                  `${
                                    message?.sender?.first_name
                                      ?.charAt(0)
                                      .toUpperCase() || ""
                                  }${
                                    message?.sender?.last_name
                                      ?.charAt(0)
                                      .toUpperCase() || ""
                                  }`}
                              </Avatar>
                            </ListItemAvatar>
                            <Box
                              sx={{
                                backgroundColor: "extraLTheme.main",
                                borderRadius: "0px 10px 10px 10px",
                                p: "10px 15px",
                                maxWidth: "45%",
                                position: "relative",
                                display: "inline-block",
                                textAlign: "left",
                                "&:before": {
                                  content: '""',
                                  position: "absolute",
                                  left: "-16px",
                                  top: "0",
                                  borderRight: "solid 16px #ECF6FF",
                                  borderBottom: "solid 16px transparent",
                                },
                                [theme.breakpoints.down("lg")]: {
                                  width: "50%",
                                },
                                [theme.breakpoints.down("md")]: {
                                  width: "50%",
                                },
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "0.9rem",
                                  color: "#419C2A",
                                  fontWeight: "600",
                                }}
                              >
                                {message?.sender?.first_name &&
                                message?.sender?.last_name
                                  ? `${message.sender.first_name} ${message.sender.last_name}`
                                  : message?.sender?.name}
                              </Typography>

                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "0.9rem",
                                  color: "lightSecondary.main",
                                  fontWeight: "500",
                                  mt: 0.5,
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                <MessageDisplay
                                  receiverMessage={message.message}
                                />
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "ltheme.main",
                                  fontWeight: "500",
                                  textAlign: "right",
                                  marginTop: "6px",
                                }}
                              >
                                {`${new Date(
                                  message?.createdAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })} \u00A0 ${new Date(
                                  message?.createdAt
                                ).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })}`}
                              </Typography>
                            </Box>
                          </Box>
                        ) : (
                          <>
                            {message?.drive_ids?.length === 1 ? (
                              <Box
                                sx={{
                                  textAlign: "left",
                                  display: "flex",
                                }}
                              >
                                <ListItemAvatar
                                  sx={{
                                    width: "42px",
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      color: "success.main",
                                      bgcolor: "success.lighter",
                                      width: "40px",
                                      height: "40px",
                                    }}
                                    src={message?.sender?.avatar}
                                  >
                                    {!message?.sender?.avatar &&
                                      `${
                                        message?.sender?.first_name
                                          ?.charAt(0)
                                          .toUpperCase() || ""
                                      }${
                                        message?.sender?.last_name
                                          ?.charAt(0)
                                          .toUpperCase() || ""
                                      }`}
                                  </Avatar>
                                </ListItemAvatar>

                                {message?.drive_ids?.map((document, index) => {
                                  const fileExtension = document.name
                                    ? document.name
                                        .split(".")
                                        .pop()
                                        .toLowerCase()
                                    : "";
                                  const isImage = [
                                    "jpg",
                                    "jpeg",
                                    "png",
                                  ].includes(fileExtension);
                                  const isPDF = fileExtension === "pdf";
                                  const isGif = fileExtension === "gif";
                                  const isDocx = fileExtension === "docx";
                                  const isDoc = fileExtension === "doc";
                                  const isMp4 = fileExtension === "mp4";
                                  const isAvi = fileExtension === "avi";
                                  const isMov = fileExtension === "mov";
                                  const isWmv = fileExtension === "wmv";
                                  const isMp3 = fileExtension === "mp3";
                                  const isWav = fileExtension === "wav";
                                  const isOgg = fileExtension === "ogg";
                                  const xlsx = fileExtension === "xlsx";
                                  const csv = fileExtension === "csv";

                                  return isImage ? (
                                    <Box
                                      key={document._id}
                                      sx={{
                                        backgroundColor: "extraLTheme.main",
                                        borderRadius: "0px 10px 10px 10px",
                                        p: "10px 15px",
                                        maxWidth: "45%",
                                        position: "relative",
                                        display: "inline-block",
                                        textAlign: "left",
                                        "&:before": {
                                          content: '""',
                                          position: "absolute",
                                          left: "-16px",
                                          top: "0",
                                          borderRight: "solid 16px #ECF6FF",
                                          borderBottom:
                                            "solid 16px transparent",
                                        },
                                        [theme.breakpoints.down("lg")]: {
                                          width: "50%",
                                        },
                                        [theme.breakpoints.down("md")]: {
                                          width: "50%",
                                        },
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "0.9rem",
                                          color: "#419C2A",
                                          fontWeight: "600",
                                          textAlign: "left",
                                        }}
                                      >
                                        {message?.sender?.first_name &&
                                        message?.sender?.last_name
                                          ? `${message.sender.first_name} ${message.sender.last_name}`
                                          : message?.sender?.name}
                                      </Typography>
                                      <Box
                                        sx={{
                                          mt: 0.5,
                                          textAlign: "left",
                                          p: "5px 5px 5px 5px",
                                          borderRadius: '8px',
                                          overFlow:'hidden',
                                          position:'relative',
                                          cursor:'pointer'
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "140px",
                                            height: "11rem",
                                            borderRadius: "8px",
                                            objectFit: "contain",
                                            mixBlendMode: "multiply",
                                          }}
                                          src={document.s3Url}
                                        />
                                        <Box
                                        tabIndex={1}
                                        sx={{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          p: "5px 5px 5px 5px",
                                          borderRadius: "8px",
                                          backgroundColor: 'rgba(0, 0, 0, 0)', 
                                          display: 'flex',
                                          alignItems: 'flex-start',
                                          justifyContent: 'flex-end',
                                          mr:0,
                                          transition: "background-color 0.3s ease",
                                          cursor: "pointer", 
                                          '&:hover': {
                                          backgroundColor: 'rgba(0, 0, 0, 0.7)', 
                                          '& .download-icon': {
                                            opacity: 1, 
                                            visibility: 'visible',
                                          },
                                          '& .preview-icon': {
                                            opacity: 1, 
                                            visibility: 'visible',
                                          },
                                        },
                                        }}
                                      >
                                        <VisibilityIcon
                                        className="preview-icon"
                                        sx={{
                                          fontSize: 20,
                                          color: "white",
                                          opacity: 0, 
                                          visibility: 'hidden', 
                                          transition: "opacity 0.3s ease, visibility 0.3s ease", 
                                        }}
                                        onClick={() =>
                                          handleImagePreview(
                                            document.s3Url,
                                          )
                                        }
                                        />
                                        <FileDownloadOutlinedIcon
                                         className="download-icon"
                                          sx={{
                                            fontSize: 20,
                                            color: "white",
                                            opacity: 0, // initially hidden
                                            visibility: 'hidden', // initially not visible
                                            transition: "opacity 0.3s ease, visibility 0.3s ease", // transition for smooth appearance
                                          }}
                                          onClick={() =>
                                            handleDownload(
                                              document.s3Url,
                                              document.name
                                            )
                                          }
                                        />
                                      </Box> 
                                      </Box>
                                      
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "0.9rem",
                                          color: "ltheme.main",
                                          fontWeight: "500",
                                          whiteSpace: "pre-wrap",
                                          wordBreak: "break-word", // Add this to break long words properly
                                          overflowWrap: "break-word", // Ensure long words wrap correctly
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <MessageDisplay
                                          receiverMessage={message.message}
                                        />
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "12px",
                                          color: "ltheme.main",
                                          fontWeight: "500",
                                          textAlign: "right",
                                        }}
                                      >
                                        {`${new Date(
                                          message?.createdAt
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })} \u00A0 ${new Date(
                                          message?.createdAt
                                        ).toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}`}
                                      </Typography>
                                    </Box>
                                  ) : (
                                    // single document
                                    <Box
                                      key={document._id}
                                      sx={{
                                        backgroundColor: "extraLTheme.main",
                                        borderRadius: "0px 10px 10px 10px",
                                        p: "10px 15px",
                                        width: "45%",
                                        position: "relative",
                                        display: "inline-block",
                                        "&:before": {
                                          content: '""',
                                          position: "absolute",
                                          left: "-16px",
                                          top: "0",
                                          borderRight: "solid 16px #ECF6FF",
                                          borderBottom:
                                            "solid 16px transparent",
                                        },
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "0.9rem",
                                          color: "#419C2A",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {message?.sender?.first_name &&
                                        message?.sender?.last_name
                                          ? `${message.sender.first_name} ${message.sender.last_name}`
                                          : message?.sender?.name}
                                      </Typography>
                                      <Stack
                                        direction="row"
                                        sx={{
                                          my: 0.5,
                                          backgroundColor: "#DBEBF8",
                                          p: 2,
                                          borderRadius: "10px",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          sx={{
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "80%",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <ListItemAvatar
                                            sx={{
                                              textAlign: "center",
                                              backgroundColor: "#fff",
                                              width: "50px",
                                              minWidth: "50px",
                                              p: "1px 1px 1px 1px",
                                              borderRadius: "10px",
                                              height: "55px",
                                              lineHeight: "50px",
                                              position: "relative",
                                            }}
                                          >
                                            <img
                                              style={{
                                                maxWidth: "40px",
                                                maxHeight: "40px",
                                                position: "absolute",
                                                // left: "50%",
                                                top: "50%",
                                                transform:
                                                  "translate(-50%, -50%)",
                                              }}
                                              src={
                                                isPDF
                                                  ? getImages("pdf.png") // Replace with your PDF icon path
                                                  : isGif
                                                  ? getImages("file.png")
                                                  : isDocx
                                                  ? getImages("doc.png")
                                                  : isDoc
                                                  ? getImages("doc.png")
                                                  : isMp4
                                                  ? getImages("mp4.png")
                                                  : isAvi
                                                  ? getImages("file.png")
                                                  : isMov
                                                  ? getImages("file.png")
                                                  : isWav
                                                  ? getImages("file.png")
                                                  : isMp3
                                                  ? getImages("mp3.png")
                                                  : // : isWav
                                                  // ? getImages("wav.png")
                                                  isOgg
                                                  ? getImages("file.png")
                                                  : xlsx
                                                  ? getImages("xls.png")
                                                  : csv
                                                  ? getImages("csv.png")
                                                  : getImages("file.png") // Replace with your default icon path
                                              }
                                            />
                                          </ListItemAvatar>
                                          <Stack
                                            sx={{
                                              pl: 2,
                                              width: "80%",
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <Typography
                                              noWrap
                                              sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                color: "secondary.main",
                                                lineHeight: "22px",
                                                mb: 0.5,
                                              }}
                                              variant="subtitle1"
                                            >
                                              <Tooltip
                                                title={message?.drive_ids?.map(
                                                  (document, index) => (
                                                    <span key={index}>
                                                      {document.name}
                                                    </span>
                                                  )
                                                )}
                                              >
                                                {message?.drive_ids?.map(
                                                  (document, index) => (
                                                    <span key={index}>
                                                      {document.name}
                                                    </span>
                                                  )
                                                )}
                                              </Tooltip>
                                            </Typography>
                                            <Typography
                                              noWrap
                                              variant="body2"
                                              sx={{
                                                fontSize: "0.8rem",
                                                fontWeight: "500",
                                                color: "ltheme.main",
                                                width: "100%",
                                              }}
                                            >
                                              {fileExtension}
                                            </Typography>
                                          </Stack>
                                        </Stack>
                                        <IconButton
                                          sx={{
                                            width: "10%",
                                            maxWidth: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            backgroundColor: "transparent", // Ensure no background color by default
                                            "&:hover": {
                                              backgroundColor: "transparent", // Prevent background color change on hover
                                            },
                                          }}
                                          onClick={() =>
                                            handleDownload(
                                              document.s3Url,
                                              document.name
                                            )
                                          }
                                        >
                                          <FileDownloadOutlinedIcon
                                            sx={{
                                              color: "lightSecondary.main",
                                            }}
                                          />
                                        </IconButton>
                                      </Stack>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "0.9rem",
                                          color: "ltheme.main",
                                          fontWeight: "500",
                                          whiteSpace: "pre-wrap",
                                          overflowWrap: "break-word", // Ensure long words wrap correctly
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <MessageDisplay
                                          receiverMessage={message.message}
                                        />
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "12px",
                                          color: "ltheme.main",
                                          fontWeight: "500",
                                          textAlign: "right",
                                        }}
                                      >
                                        {`${new Date(
                                          message?.createdAt
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })} \u00A0 ${new Date(
                                          message?.createdAt
                                        ).toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}`}
                                      </Typography>
                                    </Box>
                                  );
                                })}
                                {/* </Box> */}
                              </Box>
                            ) : (
                              // multiple document
                              <Box
                                sx={{
                                  textAlign: "left",
                                  display: "flex",
                                }}
                              >
                                <ListItemAvatar
                                  sx={{
                                    width: "42px",
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      color: "success.main",
                                      bgcolor: "success.lighter",
                                      width: "40px",
                                      height: "40px",
                                    }}
                                    src={message?.sender?.avatar}
                                  >
                                    {!message?.sender?.avatar &&
                                      `${
                                        message?.sender?.first_name
                                          ?.charAt(0)
                                          .toUpperCase() || ""
                                      }${
                                        message?.sender?.last_name
                                          ?.charAt(0)
                                          .toUpperCase() || ""
                                      }`}
                                  </Avatar>
                                </ListItemAvatar>
                                <Box
                                  sx={{
                                    backgroundColor: "extraLTheme.main",
                                    borderRadius: "0px 10px 10px 10px",
                                    p: "10px 15px",
                                    maxWidth: "45%",
                                    position: "relative",
                                    display: "inline-block",
                                    textAlign: "left",
                                    "&:before": {
                                      content: '""',
                                      position: "absolute",
                                      left: "-16px",
                                      top: "0",
                                      borderRight: "solid 16px #ECF6FF",
                                      borderBottom: "solid 16px transparent",
                                    },
                                    [theme.breakpoints.down("lg")]: {
                                      width: "50%",
                                    },
                                    [theme.breakpoints.down("md")]: {
                                      width: "50%",
                                    },
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: "0.9rem",
                                      color: "#419C2A",
                                      fontWeight: "600",
                                      textAlign: "left",
                                    }}
                                  >
                                    {message?.sender?.first_name &&
                                    message?.sender?.last_name
                                      ? `${message.sender.first_name} ${message.sender.last_name}`
                                      : message?.sender?.name}
                                  </Typography>
                                  {message?.drive_ids?.map(
                                    (document, index) => {
                                      const fileExtension = document.name
                                        ? document.name
                                            .split(".")
                                            .pop()
                                            .toLowerCase()
                                        : "";
                                      const isImage = [
                                        "jpg",
                                        "jpeg",
                                        "png",
                                      ].includes(fileExtension);
                                      const isPDF = fileExtension === "pdf";
                                      const isGif = fileExtension === "gif";
                                      const isDocx = fileExtension === "docx";
                                      const isDoc = fileExtension === "doc";
                                      const isMp4 = fileExtension === "mp4";
                                      const isAvi = fileExtension === "avi";
                                      const isMov = fileExtension === "mov";
                                      const isWmv = fileExtension === "wmv";
                                      const isMp3 = fileExtension === "mp3";
                                      const isWav = fileExtension === "wav";
                                      const isOgg = fileExtension === "ogg";
                                      const xlsx = fileExtension === "xlsx";
                                      const csv = fileExtension === "csv";

                                      return isImage ? (
                                        <Box
                                          key={document._id}
                                          // sx={{
                                          //   backgroundColor: "extraLTheme.main",
                                          //   borderRadius: "0px 10px 10px 10px",
                                          //   p: "10px 15px",
                                          //   maxWidth: "60%",
                                          //   position: "relative",
                                          //   display: "inline-block",
                                          //   "&:before": {
                                          //     content: '""',
                                          //     position: "absolute",
                                          //     left: "-16px",
                                          //     top: "0",
                                          //     borderRight: "solid 16px #ECF6FF",
                                          //     borderBottom:
                                          //       "solid 16px transparent",
                                          //   },
                                          // }}
                                          sx={{
                                            maxWidth: "45%",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              mt: 0.5,
                                              display: "flex",
                                              justifyContent: "flex-start",
                                              mb: 1,
                                              p: "5px 5px 5px 5px",
                                              overFlow:'hidden',
                                              position:'relative',
                                              cursor:'pointer'
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "140px",
                                                height: "11rem",
                                                borderRadius: "8px",
                                                objectFit: "contain",
                                                mixBlendMode: "multiply",
                                              }}
                                              src={document.s3Url}
                                            />
  
                                        <Box
                                        tabIndex={-1}
                                        sx={{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          width: "150px",
                                          height: "11rem",
                                          p: "5px 5px 5px 5px",
                                          borderRadius: "8px",
                                          backgroundColor: 'rgba(0, 0, 0, 0)', // transparent by default
                                          display: 'flex',
                                          alignItems: 'flex-start',
                                          justifyContent: 'flex-end',
                                          mr:0,
                                          transition: "background-color 0.3s ease",
                                          cursor: "pointer", 
                                          '&:hover': {
                                          backgroundColor: 'rgba(0, 0, 0, 0.7)', 
                                          '& .download-icon': {
                                            opacity: 1, 
                                            visibility: 'visible',
                                          },
                                          '& .preview-icon': {
                                            opacity: 1, 
                                            visibility: 'visible',
                                          },
                                        },
                                        }}
                                      >
                                        <VisibilityIcon
                                        className="preview-icon"
                                        sx={{
                                          fontSize: 20,
                                          color: "white",
                                          opacity: 0, 
                                          visibility: 'hidden', 
                                          transition: "opacity 0.3s ease, visibility 0.3s ease", 
                                        }}
                                        onClick={() =>
                                          handleImagePreview(
                                            document.s3Url,
                                          )
                                        }
                                        />
                                          <FileDownloadOutlinedIcon
                                           className="download-icon"
                                            sx={{
                                              fontSize: 20,
                                              color: "white",
                                              opacity: 0, // initially hidden
                                              visibility: 'hidden', // initially not visible
                                              transition: "opacity 0.3s ease, visibility 0.3s ease", // transition for smooth appearance
                                            }}
                                            onClick={() =>
                                              handleDownload(
                                                document.s3Url,
                                                document.name
                                              )
                                            }
                                          />
                                      </Box>
                                          </Box>
                                          {/* <Typography
                                          variant="body2"
                                          sx={{
                                            fontSize: "12px",
                                            color: "ltheme.main",
                                            fontWeight: "500",
                                            textAlign: "right",
                                          }}
                                        >
                                          {`${new Date(
                                            document?.createdAt
                                          ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                          })} \u00A0 ${new Date(
                                            document?.createdAt
                                          ).toLocaleTimeString("en-US", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                          })}`}
                                        </Typography> */}
                                        </Box>
                                      ) : (
                                        //  documents
                                        <Box key={document._id}>
                                          <Stack
                                            direction="row"
                                            sx={{
                                              my: 0.5,
                                              backgroundColor: "#DBEBF8",
                                              p: 2,
                                              borderRadius: "10px",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              width: "100%",
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <Stack
                                              direction="row"
                                              sx={{
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "80%",
                                                maxWidth: "100%",
                                              }}
                                            >
                                              <ListItemAvatar
                                                sx={{
                                                  textAlign: "center",
                                                  backgroundColor: "#fff",
                                                  width: "50px",
                                                  minWidth: "50px",
                                                  p: "1px 1px 1px 1px",
                                                  borderRadius: "10px",
                                                  height: "55px",
                                                  lineHeight: "50px",
                                                  position: "relative",
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    maxWidth: "40px",
                                                    maxHeight: "40px",
                                                    position: "absolute",
                                                    // left: "50%",
                                                    top: "50%",
                                                    transform:
                                                      "translate(-50%, -50%)",
                                                  }}
                                                  src={
                                                    isPDF
                                                      ? getImages("pdf.png") // Replace with your PDF icon path
                                                      : isGif
                                                      ? getImages("file.png")
                                                      : isDocx
                                                      ? getImages("doc.png")
                                                      : isDoc
                                                      ? getImages("doc.png")
                                                      : isMp4
                                                      ? getImages("mp4.png")
                                                      : isAvi
                                                      ? getImages("file.png")
                                                      : isMov
                                                      ? getImages("file.png")
                                                      : isWav
                                                      ? getImages("file.png")
                                                      : isMp3
                                                      ? getImages("mp3.png")
                                                      : // : isWav
                                                      // ? getImages("wav.png")
                                                      isOgg
                                                      ? getImages("file.png")
                                                      : xlsx
                                                      ? getImages("xls.png")
                                                      : csv
                                                      ? getImages("csv.png")
                                                      : getImages("file.png") // Replace with your default icon path
                                                  }
                                                />
                                              </ListItemAvatar>
                                              <Stack
                                                sx={{
                                                  pl: 2,
                                                  width: "80%",
                                                  maxWidth: "100%",
                                                }}
                                              >
                                                <Typography
                                                  noWrap
                                                  sx={{
                                                    fontSize: "0.9rem",
                                                    fontWeight: "600",
                                                    color: "secondary.main",
                                                    lineHeight: "22px",
                                                    mb: 0.5,
                                                  }}
                                                  variant="subtitle1"
                                                >
                                                  <Tooltip
                                                    title={message?.drive_ids?.map(
                                                      (document, index) => (
                                                        <span key={index}>
                                                          {document.name}
                                                        </span>
                                                      )
                                                    )}
                                                  >
                                                    {message?.drive_ids?.map(
                                                      (document, index) => (
                                                        <span key={index}>
                                                          {document.name}
                                                        </span>
                                                      )
                                                    )}
                                                  </Tooltip>
                                                </Typography>
                                                <Typography
                                                  noWrap
                                                  variant="body2"
                                                  sx={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: "500",
                                                    color: "ltheme.main",
                                                    width: "100%",
                                                  }}
                                                >
                                                  {fileExtension}
                                                </Typography>
                                              </Stack>
                                            </Stack>
                                            <IconButton
                                              sx={{
                                                width: "10%",
                                                maxWidth: "100%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                backgroundColor: "transparent", // Ensure no background color by default
                                                "&:hover": {
                                                  backgroundColor:
                                                    "transparent", // Prevent background color change on hover
                                                },
                                              }}
                                              onClick={() =>
                                                handleDownload(
                                                  document.s3Url,
                                                  document.name
                                                )
                                              }
                                            >
                                              <FileDownloadOutlinedIcon
                                                sx={{
                                                  color: "lightSecondary.main",
                                                }}
                                              />
                                            </IconButton>
                                          </Stack>
                                        </Box>
                                      );
                                    }
                                  )}
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: "0.9rem",
                                      color: "ltheme.main",
                                      fontWeight: "500",
                                      whiteSpace: "pre-wrap",
                                      overflowWrap: "break-word", // Ensure long words wrap correctly
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <MessageDisplay
                                      receiverMessage={message.message}
                                    />
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: "12px",
                                      color: "ltheme.main",
                                      fontWeight: "500",
                                      textAlign: "right",
                                    }}
                                  >
                                    {`${new Date(
                                      message?.createdAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    })} \u00A0 ${new Date(
                                      message?.createdAt
                                    ).toLocaleTimeString("en-US", {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    })}`}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
              </>
            )}
          </InfiniteScroll>
        </Box>
        {/* Chat Footer */}
        <Box
          sx={{
            backgroundColor: "extraLTheme.main",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "ltheme.main",
            p: 0.5,
            borderRadius: "0 0 10px 10px",
            display: "flex",
            alignItems: "center",
            marginTop: "25px",
            justifyContent: "space-between",
            height: "50px",
            maxHeight: "50px",
          }}
        >
          {/* <Button
            variant="none"
            sx={{
              textTransform: "inherit",
              p: "8px 8px",
              minWidth: "inherit",
              boxShadow: "none",
            }}
          >
            <SentimentSatisfiedAltIcon
              sx={{
                color: "lightSecondary.main",
                fontSize: "20px",
              }}
            />
          </Button> */}
          {/* <Button
            variant="none"
            sx={{
              textTransform: "inherit",
              p: "8px 8px",
              minWidth: "inherit",
              boxShadow: "none",
            }}
          >
            <AttachFileIcon
              onClick={handleOpenUploadModal}
              sx={{
                color: "lightSecondary.main",
                fontSize: "20px",
              }}
            />
          </Button> */}

          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              variant="none"
              sx={{
                textTransform: "inherit",
                p: "8px 8px",
                minWidth: "inherit",
                boxShadow: "none",
              }}
              onClick={() => document.getElementById("fileInput").click()} // Trigger file input click
            >
              <AttachFileIcon
                sx={{
                  color: "lightSecondary.main",
                  fontSize: "20px",
                }}
              />
            </Button>

            <input
              type="file"
              id="fileInput"
              multiple // To allow multiple file selection
              style={{ display: "none" }} // Hidden input
              onChange={handleFileChange}
            />
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              placeholder="Type a message"
              disabled={sendMessageLoading}
              value={userInput}
              multiline
              minRows={1} // Start with 2 rows
              maxRows={2} // Limit to 3 rows
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.shiftKey) {
                  e.preventDefault();
                  setUserInput((prev) => prev + "\n"); // Add new line on Shift + Enter
                } else if (
                  e.key === "Enter" &&
                  !e.shiftKey &&
                  !sendMessageLoading
                ) {
                  e.preventDefault();
                  handleMessage(); // Send the message when Enter is pressed without Shift
                }
              }}
              onChange={(e) => {
                const inputValue = e.target.value;
                const wordCount = inputValue.trim().split(/\s+/).length; // Calculate the number of words

                if (wordCount <= 1000) {
                  setUserInput(inputValue); // Update user input if within limit
                  setIsLimitExceeded(false); // Reset the limit exceeded state
                } else {
                  setIsLimitExceeded(true); // Set limit exceeded state
                }
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputBase-input": {
                  padding: "0px 10px",
                  fontSize: "0.95rem",
                  color: "lightSecondary.main",
                  fontWeight: "600",
                  overflowY: "auto",
                  maxHeight: "100px", // Limit the height of the input area
                  // Apply custom scrollbar styles
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#9C9C9C",
                    borderRadius: "4px",
                  },
                },
              }}
            />
          </Box>
          {/* <Button
            variant="none"
            sx={{
              textTransform: "inherit",
              p: "8px 8px",
              minWidth: "inherit",
              boxShadow: "none",
              borderRadius: "0",
              borderRight: "solid 1px",
              borderRightColor: "lightSecondary.main",
            }}
          >
            <MicIcon
              sx={{
                color: "lightSecondary.main",
                fontSize: "20px",
              }}
            />
          </Button> */}
          <Button
            variant="contained"
            disabled={sendMessageLoading}
            onClick={handleMessage}
            sx={{
              textTransform: "inherit",
              p: "8px 8px",
              backgroundColor: "lightSecondary.main",
              minWidth: "inherit",
              boxShadow: "none",
              // justifyContent: "flex-end",
            }}
          >
            <SendIcon
              sx={{
                color: "#fff",
                fontSize: "15px",
              }}
            />
          </Button>
        </Box>
        {/* Conditional message if limit is exceeded */}
        {/* {isLimitExceeded && (
        <>
        <Box
        sx={{
          display:'flex',
          justifyContent:'flex-start'
        }}
        >
        <Typography 
         sx={{ 
          color: "red.main"
           }}>
          Message limit exceeded...
        </Typography>
        </Box>
        </>
      )} */}
      </Box>
      <Modals
        open={openUploadModal}
        handleClose={handleCloseUploadModal}
        modalWidth={500}
      >
        <SendDocument
          handleClose={handleCloseUploadModal}
          handleCloseUploadModal={handleCloseUploadModal}
          showToastMessage={showToastMessage}
          files={files}
          setFiles={setFiles}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          groupData={groupData}
          setGroupData={setGroupData}
          userInput={userInput}
          setUserInput={setUserInput}
          groupChatId={groupChatId}
          handleFileChange={handleFileChange}
        />
      </Modals>
      <Modals
        open={openManageUsers}
        handleClose={handleCloseUManageUsers}
        modalWidth={500}
      >
        <ManageUsers
          handleClose={handleCloseUManageUsers}
          groupData={groupData}
          chatId={groupChatId}
          refetch={refetchMessages}
          showToastMessage={showToastMessage}
        />
      </Modals>
      <Modals
        open={imagePreview}
        handleClose={handleImagePreviewClosed}
        modalWidth={500}
      >
        <img
        style={{
          width: "100%",
          height: "auto",
          borderRadius: "8px",
          objectFit: "contain",
          mixBlendMode: "multiply",
        }}
        src={imageUrl}
        alt="Preview"
      />
        
      </Modals>
    </>
  );
};

export default GroupChatContainer;
