import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  Menu,
  Card,
  CardContent,
  IconButton,
  styled,
  InputBase,
  Radio,
  FormControlLabel,
  Badge,
  Select,
  FormControl,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import SearchIcon from "@mui/icons-material/Search";
import { PlusOutlined } from "@ant-design/icons";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { getImages } from "../../commonComponents/const";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import NorthOutlinedIcon from "@mui/icons-material/NorthOutlined";
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarPurple500OutlinedIcon from "@mui/icons-material/StarPurple500Outlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useFetchRequirementBoardQuery } from "../../redux/api/Requirement Boards/getRequirementBoardList";
import { useFetchIndustryListQuery } from "../../redux/api/Industry/getIndustry";
import { useDispatch, useSelector } from "react-redux";
import { getBoardDetail } from "../../redux/features/kanbanSlice";
import { toast, ToastContainer } from "react-toastify";
import CustomLoader from "../../utils/Loader/CustomLoader";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { getBoardId, getBoardName } from "../../redux/features/boardSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import NoDataPage from "../../utils/Error/noDataFound";
import { resetRefetchRequirementBoard } from "../../redux/features/refetch";
import Modals from "../../commonComponents/Modals";
import BoardSetting from "../../commonComponents/Kanban/BoardSetting";
import DeleteBoardAlert from "../../commonComponents/Kanban/DeleteBoardAlert";
import NoDataScreen from "../../commonComponents/noData";
import { useFetchUseCaseListQuery } from "../../redux/api/usecase/getUseCase";
import BusinessUserBsiCoLabSkull from "../../commonComponents/BsiCoLab/businessUserBsiCoLabSkull";
import NoRequirementBoardScreen from "../../commonComponents/BsiCoLab/businessUserNoBoardScreen";

const BsiCoLab = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  const createRequirementLink = process.env.REACT_APP_CREATE_REQUIREMENT_URL
  // toast messages
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  // Search Box
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const [openSort, setOpenSort] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [sortOption, setSortOption] = useState("asc");
  const [industry, setIndustry] = useState("");
  const [usecase, setUseCase] = useState("");
  const [tech, setTech] = useState("");
  const [techValue, setTechValue] = useState("");
  const [industryFilterId, setIndustryFilterId] = useState("");
  const [useCaseFilterId, setUseCaseFilterId] = useState("");
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [boardId, setBoardId] = useState("");
  const [boardName, setBoardName] = useState("");
  const [expectedItemsCount, setExpectedItemsCount] = useState(9);
  // getting userId from localStorage
  const location = useLocation();
  const userId = localStorage.getItem("userId");

  // apiCall
  const {
    data: boardData,
    error: boardError,
    isLoading: boardLoading,
    refetch,
  } = useFetchRequirementBoardQuery({
    user_id: userId,
    boardName: searchValue,
    sort: sortOption,
    industryFilterId: industryFilterId,
    techFilterValue: techValue,
  });

  // set refetchKanban in reduxStore
  const refetchRequirementBoard = useSelector(
    (state) => state.refetch.refetchRequirementBoard
  );
  useEffect(() => {
    if (refetchRequirementBoard) {
      refetch().finally(() => {
        dispatch(resetRefetchRequirementBoard());
      });
    }
  }, [refetchRequirementBoard, refetch, dispatch]);

  // fetch industry
  const {
    data: IndustryData,
    isLoading: industryLoading,
    error: industryError,
  } = useFetchIndustryListQuery();
  // fetch usecase
  const {
    data: useCaseData,
    isLoading: useCaseLoading,
    error: useCaseError,
  } = useFetchUseCaseListQuery();

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(!openSort);
  };
  const handleCloseSort = (event) => {
    setAnchorElSort(null);
    setOpenSort(false);
    setSelectedSortOption(event.target.value);
  };
  const handleSorting = (event) => {
    setSortOption(event.target.value);
    setOpenSort(false);
  };

  const handleIndustry = (event) => {
    setIndustry(event.target.value);
  };
  
  const handleUseCase = (event) => {
    setUseCase(event.target.value);
  };
  
  const handleTech = (event) => {
    setTech(event.target.value);
  };

  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(!openFilter);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };
  const handleFilter = () => {
    setIndustryFilterId(industry);
    setTechValue(tech);
    setOpenFilter(false);
  };
  const handleFilterClear = () => {
    setIndustry("");
    setTech("");
    setIndustryFilterId("");
    setTechValue("");
    setOpenFilter(false);
  };
  // board setting
  // list1Settings Modal
  const [openBoardSettings, setOpenBoardSettings] = useState(false);
  const boardSettingsHandle = (boardId, boardName) => {
    setOpenBoardSettings(true);
    setBoardId(boardId);
    setBoardName(boardName);
  };
  const closeBoardSettings = () => {
    setOpenBoardSettings(false);
  };
  // Delete list1Alert Modal
  const [openDeleteBoardAlert, setOpenDeleteBoardAlert] = useState(false);
  const deleteBoardAlertHandle = () => {
    setOpenDeleteBoardAlert(true);
    setOpenBoardSettings(false);
  };
  const closeDeleteBoardAlert = () => {
    setOpenDeleteBoardAlert(false);
  };
  const goBackFromAlertHandle = () => {
    setOpenDeleteBoardAlert(false);
    setOpenBoardSettings(true);
  };

  useEffect(() => {
    if (boardLoading ) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [boardLoading, dispatch]);

  // Searching logic
  const handleInputChange = (event) => {
    setSearchParams(event.target.value.trim());
  };

  const handleRequirementBoardSearch = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue(""); 
        await refetch();
        return;
      }

      setSearchValue(inputValue);
      dispatch(showLoader());
      try {
        // Await refetch and handle search results
        await refetch();
        if (boardError) {
          const errorMessage = boardData?.message || "No Data Found";
          showToastMessage(errorMessage, "error");
          return;
        }
        // // Check if boardData is empty or doesn't contain results
        // if (!boardData || boardData.length === 0) {
        //   navigate("/404"); // Redirect to a 404 page
        //   return;
        // }
      } catch (boardError) {
        // Handle errors if needed
        showToastMessage("An error occurred during search", "error");
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
  };
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue(""); // Reset search value to empty
    refetch(); // Refetch all data
  };

  const handleCardClick = (boardId, boardName, industry) => {
    localStorage.setItem("boardId", boardId);
    localStorage.setItem("boardName", boardName);
    localStorage.setItem("industry", industry);
    navigate("/bsi-co-lab/kanban");
  };
  const handleViewRequirementClick = (boardId, boardName) => {
    localStorage.setItem("viewRequirementBoardId", boardId);
    localStorage.setItem("viewRequirementBoardName", boardName);
    navigate("/bsi-co-lab-requirements");
  };  

  if (boardLoading) {
    dispatch(showLoader());
  } else {
    dispatch(hideLoader());
  }

  const handleCreateRequirement = ()=>{
    window.location.href = createRequirementLink ;
  }

  return (
    <>
      <Grid container rowSpacing={{ md: 4.5, xs: 1 }} columnSpacing={2.75}>
        <Grid item lg={6} md={6} sm={4} xs={12}>
          {/* <Link to="https://www.blueskyintel.com/create-requirement"> */}
            <Button
              variant="contained"
              onClick={handleCreateRequirement}
              sx={{
                textTransform: "inherit",
                display:'flex',
                gap:'5px',
                alignItems:'center',
                justifyContent: "center",
                p: "10px 25px",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                },
                [theme.breakpoints.down("md")]: {
                  p: isDrawerOpen?"0px 7px":"9px 7px",
                },
                [theme.breakpoints.down("sm")]: {
                  p: "10px 25px",
                },
                [theme.breakpoints.down("xs")]: {
                  p: "10px 25px",
                },
              }}
            >
              <PlusOutlined
                style={{
                  // width: "20px",
                  // height: "20px",
                  // marginRight: "1px",
                  // marginTop: "4px",
                }}
              />
              Create New Requirement
            </Button>
          {/* </Link> */}
        </Grid>
        <Grid item xs={12} md={6} lg={6} sm={8}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              [theme.breakpoints.down("sm")]: {
                flexWrap: "wrap",
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                mr: 2,
                width: "45%",
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  mr: 0,
                },
              }}
            >
              <Search>
                <StyledInputBase
                  placeholder="Search Boards"
                  inputProps={{ "aria-label": "search" }}
                  value={searchParams}
                  onChange={handleInputChange}
                  onKeyDown={handleRequirementBoardSearch}
                  autoFocus
                />
                {searchParams ? (
                  <IconButton
                    onClick={handleClearSearch}
                    sx={{ position: "absolute", right: 0 }}
                  >
                    <CancelIcon sx={{ color: "lightSecondary.main" }} />
                  </IconButton>
                ) : (
                  <SearchIconWrapper>
                    <SearchIcon sx={{ color: "lightSecondary.main" }} />
                  </SearchIconWrapper>
                )}
              </Search>
            </Box>
            <Box
              sx={{
                mr: 2,
                [theme.breakpoints.down("sm")]: {
                  width: "calc(50% - 0.5rem)",
                  mr: "0.5rem",
                },
              }}
            >
              <Button
                id="basic-button"
                aria-controls={openSort ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSort ? "true" : undefined}
                onClick={handleClickSort}
                sx={{
                  background: "#fff",
                  fontSize: "0.9rem",
                  color: "lightSecondary.main",
                  textTransform: "inherit",
                  fontWeight: "600",
                  p: "10px 20px",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
              >
                Sort
                <KeyboardArrowDownOutlined sx={{}} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElSort}
                open={openSort}
                onClose={handleCloseSort}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  "& .MuiMenu-paper": {
                    borderRadius: "10px",
                    border: "solid 1px #106EED",
                  },
                }}
              >
                {/* <MenuItem
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: "0.9rem",
                                            color: "#2A589C",
                                            fontWeight: "600",
                                        }
                                    }}
                                    onClick={()=>{handleCloseSort ; handleSorting() }}>
                                    <FormControlLabel
                                        value="CreatedUp" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedUp'} />} label="Created" />
                                    <NorthOutlinedIcon sx={{
                                        fontSize: "1.1rem",
                                        color: "#2A589C"
                                    }} />
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: "0.9rem",
                                            color: "#2A589C",
                                            fontWeight: "600",
                                        }
                                    }}
                                    onClick={handleCloseSort}>
                                    <FormControlLabel
                                        value="CreatedDown" control={<Radio sx={{ paddingY: 0, color: "#2A589C" }} checked={selectedSortOption === 'CreatedDown'} />} label="Created" />
                                    <SouthOutlinedIcon sx={{
                                        fontSize: "1.1rem",
                                        color: "#2A589C"
                                    }} />
                                </MenuItem> */}
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& .MuiFormControlLabel-root": {
                      width: "100%", // Make the FormControlLabel take the full width
                      height: "100%",
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "0.9rem",
                      color: "#2A589C",
                      fontWeight: "600",
                    },
                  }}
                  onClick={handleSorting}
                >
                  <FormControlLabel
                    value="asc"
                    sx={{ width: "100%", height: "100%" }}
                    control={
                      <Radio
                        sx={{
                          paddingY: 0,
                          color: "#2A589C",
                          width: "100%",
                          height: "100%",
                        }}
                        checked={sortOption === "asc"}
                      />
                    }
                    label="Name A-Z"
                  />
                </MenuItem>
                <MenuItem
                  sx={{
                    "& .MuiFormControlLabel-root": {
                      width: "100%",
                      height: "100%", // Make the FormControlLabel take the full width
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "0.9rem",
                      color: "#2A589C",
                      fontWeight: "600",
                    },
                  }}
                  onClick={handleSorting}
                >
                  <FormControlLabel
                    value="dsc"
                    control={
                      <Radio
                        sx={{ paddingY: 0, color: "#2A589C" }}
                        checked={sortOption === "dsc"}
                      />
                    }
                    label="Name Z-A"
                  />
                </MenuItem>
              </Menu>
            </Box>
            <Box
              sx={{
                [theme.breakpoints.down("sm")]: {
                  width: "50%",
                },
              }}
            >
              <Button
                id="basic-button"
                aria-controls={openFilter ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openFilter ? "true" : undefined}
                onClick={handleClickFilter}
                sx={{
                  background: "#fff",
                  fontSize: "0.9rem",
                  color: "lightSecondary.main",
                  textTransform: "inherit",
                  fontWeight: "600",
                  p: "10px 20px",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
              >
                Filter
                <KeyboardArrowDownOutlined />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElFilter}
                open={openFilter}
                onClose={handleCloseFilter}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  "& .MuiMenu-paper": {
                    borderRadius: "10px",
                    border: "solid 1px #106EED",
                    minWidth: "280px",
                    px: 2.3,
                    py: 1,
                  },
                }}
              >
                {/* Select Industry */}
                <Box
                  sx={{
                    mb: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "ltheme.main",
                      lineHeight: "24px",
                    }}
                    variant="body2"
                  >
                    Select Industry
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={industry}
                      onChange={handleIndustry}
                      displayEmpty
                      IconComponent={KeyboardArrowDownOutlinedIcon}
                      sx={{
                        background: "#ECF6FF",
                        "& .MuiSelect-select": {
                          minHeight: "20px",
                          padding: "11px 14px",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          fontSize: "0.9rem",
                        },
                        "& .MuiSelect-icon": {
                          color: "lightSecondary.main",
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: "240px",
                            overflowY: "auto",
                            "&::-webkit-scrollbar": {
                              width: "6px",
                              borderRadius: "4px",
                            },
                            "&::-webkit-scrollbar-track": {
                              backgroundColor: "transparent",
                              borderRadius: "4px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#9C9C9C",
                              borderRadius: "4px",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Industry
                      </MenuItem>
                      {Array.isArray(IndustryData?.data) &&
                        IndustryData.data.length > 0 &&
                        IndustryData.data.map((industry) => (
                          <MenuItem key={industry._id} value={industry._id}>
                            {industry.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                {/* Select UseCase */}
                <Box
                  sx={{
                    mb: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "ltheme.main",
                      lineHeight: "24px",
                    }}
                    variant="body2"
                  >
                    Select Use Case
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={usecase}
                      onChange={handleUseCase}
                      displayEmpty
                      IconComponent={KeyboardArrowDownOutlinedIcon}
                      sx={{
                        background: "#ECF6FF",
                        "& .MuiSelect-select": {
                          minHeight: "20px",
                          padding: "11px 14px",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          fontSize: "0.9rem",
                        },
                        "& .MuiSelect-icon": {
                          color: "lightSecondary.main",
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: "240px",
                            overflowY: "auto",
                            "&::-webkit-scrollbar": {
                              width: "6px",
                              borderRadius: "4px",
                            },
                            "&::-webkit-scrollbar-track": {
                              backgroundColor: "transparent",
                              borderRadius: "4px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#9C9C9C",
                              borderRadius: "4px",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Use Case
                      </MenuItem>
                      {Array.isArray(useCaseData?.data) &&
                        useCaseData?.data.length > 0 &&
                        useCaseData?.data.map((usecase) => (
                          <MenuItem key={usecase._id} value={usecase._id}>
                            {usecase.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                
                {/* Select Tech */}
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "ltheme.main",
                      lineHeight: "24px",
                    }}
                    variant="body2"
                  >
                    Select Tech
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={tech}
                      onChange={handleTech}
                      displayEmpty
                      IconComponent={KeyboardArrowDownOutlinedIcon}
                      sx={{
                        background: "#ECF6FF",
                        "& .MuiSelect-select": {
                          minHeight: "20px",
                          padding: "11px 14px",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          fontSize: "0.9rem",
                        },
                        "& .MuiSelect-icon": {
                          color: "lightSecondary.main",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Tech
                      </MenuItem>
                      <MenuItem value={"AI"}>AI</MenuItem>
                      <MenuItem value={"Blockchain"}>Blockchain</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "50%",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        width: "100%",
                        textTransform: "inherit",
                        p: "7px 15px",
                      }}
                      onClick={handleFilterClear}
                    >
                      Clear
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        textTransform: "inherit",
                        p: "7px 15px",
                        boxShadow: "none",
                      }}
                      onClick={handleFilter}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Menu>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid mt={0.5} container rowSpacing={2} columnSpacing={2}
      sx={{
        width:'100%',
        height:'100%'
      }}
      >
        {boardData && boardData.length > 0 ? (
          boardData.map((board, index) => (
            <Grid 
            item 
            xs={12} 
            md={isDrawerOpen ? 6 : 6} // Change the size when drawer is open
            lg={isDrawerOpen ? 5 : 4} // Change the size when drawer is open
            key={board.board_id}>
              <Card
                sx={{
                  boxShadow: "none",
                  borderRadius: "10px",
                }}
                onClick={() =>
                  handleCardClick(
                    board.board_id,
                    board.boardName,
                    board.industryName
                  )
                }
              >
                <CardContent
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  <Box
                    sx={{
                      p: "0",
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      display={"flex"}
                      sx={{
                        px: "0",
                        alignItems: "center",
                        minWidth: "90%",
                        maxWidth:'90%'
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            bgcolor: "lightSecondary.main",
                            px: 2.8,
                            fontSize: "1.8rem",
                            fontWeight: "700",
                            width: "90px",
                            height: "90px",
                            borderRadius: "10px",
                            [theme.breakpoints.down("md")]: {
                              width: "70px",
                              height: "70px",
                              fontSize: "1.2rem",
                            },
                          }}
                          variant="rounded"
                        >
                          {board.boardTitle}
                        </Avatar>
                      </ListItemAvatar>
                      
                      <ListItemText
                        sx={{
                          pl: "20px",
                          maxWidth: "80%",
                          [theme.breakpoints.down("lg")]: {
                            maxWidth: "80%",
                          },
                          [theme.breakpoints.down("md")]: {
                            pl: "10px",
                            maxWidth: "80%",
                          },
                          [theme.breakpoints.down("sm")]: {
                            pl: "10px",
                            maxWidth: "80%",
                          },
                        }}
                      >
                        <Typography
                          noWrap
                          sx={{
                            fontSize: "1.1rem",
                            fontWeight: "600",
                            color: "secondary.main",
                            lineHeight: "24px",
                            maxWidth: "230px",

                            [theme.breakpoints.down("lg")]: {
                              maxWidth: "230px",
                            },
                            [theme.breakpoints.down("md")]: {
                              maxWidth: "400px",
                            },
                            [theme.breakpoints.down("sm")]: {
                              fontSize: "1rem",
                              maxWidth: "400px",
                            },
                          }}
                          variant="h6"
                        >
                          <Tooltip title={board.boardName}>
                            {board.boardName}
                          </Tooltip>
                        </Typography>
                        <Box
                          sx={{
                          width:'100%'
                          }}
                        >
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "ltheme.main",        
                              maxWidth: "230px",        
                            }}
                          >
                            Solutions :{" "}
                            {board.solutionCount && board.solutionCount > 0
                              ? board.solutionCount
                              : 0}
                          </Typography>
                          <Typography
                            variant="body2"
                            noWrap
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "ltheme.main",
                              maxWidth: "230px",

                            }}
                          >
                            Industry : {" "}
                            <Tooltip title={board.industryName}>
                            {board.industryName}
                          </Tooltip>
                          </Typography>
                        </Box>

                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "inherit",
                            marginLeft: "2px",
                            p: "5px 20px",
                            borderRadius: "30px",
                            boxShadow: "none",
                            marginTop: "4px",
                            "@media (min-width: 1200px) and (max-width: 2560px) and (max-height: 878px)": {
                                  // Custom styles for the specific screen size
                                  p: isDrawerOpen? "5px 20px" : "2px 10px",
                                  fontSize: isDrawerOpen? "0.8rem" : "0.8rem",
                                }, 
                            "@media (min-width: 900px) and (max-width: 1200px) and (max-height: 878px)": {
                              // Custom styles for the specific screen size
                              p: isDrawerOpen? "2px 8px" : "2px 10px",
                              fontSize: isDrawerOpen? "0.6rem" : "0.8rem",
                            }, 
                            [theme.breakpoints.down("md")]: {
                              p: "2px 10px",
                              fontSize: "0.8rem",
                            },
                            [theme.breakpoints.down("sm")]: {
                              p: "2px 10px",
                              fontSize: "0.8rem",
                            },
                          }}
                          onClick={(event) => {
                            event.stopPropagation(); // Prevents the card click event from being triggered
                            handleViewRequirementClick(board.board_id,board.boardName);
                          }}
                        >
                          View Requirement
                        </Button>
                      </ListItemText>
                    </Box>
                    
                    <Box
                      display={"inline-flex"}
                      sx={{
                        px: "0",
                        // alignItems: "center",
                        paddingLeft: isDrawerOpen?'10px':'15px',
                        zIndex: 1,
                        minWidth: "10%",
                        maxWidth:'10%'
                      }}
                    >
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation(); // Prevents the card click event from being triggered
                          boardSettingsHandle(board.board_id, board.boardName);
                        }}
                      >
                        <SettingsOutlinedIcon
                          sx={{ color: "lightSecondary.main" }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  {/* <Box sx={{ mt: 3 }}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "lightSecondary.main",
                        width: "100%",
                      }}
                    >
                      Recent conversations :
                    </Typography>
                    <Stack
                      sx={{
                        flexDirection: "inherit",
                        alignItems: "center",
                        mt: 0.7,
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down("md")]: {
                              minWidth: "36px",
                            },
                          }}
                        >
                          <Avatar
                            sx={{
                              color: "success.main",
                              bgcolor: "success.lighter",
                              width: "48px",
                              height: "48px",
                              [theme.breakpoints.down("md")]: {
                                width: "34px",
                                height: "34px",
                              },
                            }}
                            src={getImages("avatar-1.png")}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down("md")]: {
                              minWidth: "36px",
                            },
                          }}
                        >
                          <Avatar
                            sx={{
                              color: "success.main",
                              bgcolor: "success.lighter",
                              width: "48px",
                              height: "48px",
                              [theme.breakpoints.down("md")]: {
                                width: "34px",
                                height: "34px",
                              },
                            }}
                            src={getImages("2.jpg")}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            width: "48px",
                            [theme.breakpoints.down("md")]: {
                              minWidth: "36px",
                              width: "36px",
                            },
                          }}
                        >
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <SmallAvatar
                                sx={{
                                  width: "26px",
                                  height: "26px",
                                  [theme.breakpoints.down("md")]: {
                                    width: "22px",
                                    height: "22px",
                                  },
                                }}
                                alt="Remy Sharp"
                                src={getImages("2.jpg")}
                              />
                            }
                          >
                            <Avatar
                              alt="Travis Howard"
                              sx={{
                                color: "success.main",
                                bgcolor: "success.lighter",
                                width: "48px",
                                height: "48px",
                                [theme.breakpoints.down("md")]: {
                                  width: "34px",
                                  height: "34px",
                                },
                              }}
                              src={getImages("4.jpg")}
                            />
                          </Badge>
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            [theme.breakpoints.down("md")]: {
                              minWidth: "36px",
                            },
                          }}
                        >
                          <Avatar
                            sx={{
                              color: "success.main",
                              bgcolor: "success.lighter",
                              width: "48px",
                              height: "48px",
                              [theme.breakpoints.down("md")]: {
                                width: "34px",
                                height: "34px",
                              },
                            }}
                            src={getImages("avatar-1.png")}
                          />
                        </ListItemAvatar>
                        <ListItemAvatar
                          sx={{
                            width: "18px",
                          }}
                        >
                          <Badge overlap="circular">
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              badgeContent={
                                <SmallAvatar
                                  sx={{
                                    width: "26px",
                                    height: "26px",
                                    ml: 1.5,
                                    [theme.breakpoints.down("md")]: {
                                      width: "22px",
                                      height: "22px",
                                    },
                                  }}
                                  alt="Remy Sharp"
                                  src={getImages("2.jpg")}
                                />
                              }
                            />
                            <Avatar
                              alt="Travis Howard"
                              sx={{
                                color: "success.main",
                                bgcolor: "success.lighter",
                                width: "48px",
                                height: "48px",
                                [theme.breakpoints.down("md")]: {
                                  width: "34px",
                                  height: "34px",
                                },
                              }}
                              src={getImages("1.jpg")}
                            />
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <SmallAvatar
                                  sx={{
                                    mr: 1.5,
                                  }}
                                  alt="Remy Sharp"
                                  src={getImages("2.jpg")}
                                />
                              }
                            />
                          </Badge>
                        </ListItemAvatar>
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "0.95rem",
                            color: "ltheme.main",
                            fontWeight: "600",
                          }}
                        >
                          <Badge
                            color="secondary"
                            badgeContent=" "
                            variant="dot"
                            sx={{
                              "& .MuiBadge-badge": {
                                backgroundColor: "red.main",
                              },
                            }}
                          >
                            <MessageOutlinedIcon
                              sx={{
                                verticalAlign: "middle",
                                fill: "ltheme.main",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </Badge>
                          &nbsp; 13
                        </Typography>
                      </Box>
                    </Stack>
                  </Box> */}
                </CardContent>
              </Card>
              {/* </Link> */}
            </Grid>
          ))
        ) : (
          boardData && boardData.length == 0 ?
          (
            <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
              width: "100%",
            }}
          >
            <NoRequirementBoardScreen />
          </Box>
          ):
          (
            <Grid container spacing={2} rowSpacing={4} sx={{ py: 2, px: 1 }}>
            {[...Array(expectedItemsCount)].map((_, index) => (
                   <BusinessUserBsiCoLabSkull/>
              ))}
           </Grid>
           
          )
        )}
      </Grid>
      {/* <Outlet/> */}
      <Modals
        open={openBoardSettings}
        handleClose={closeBoardSettings}
        modalWidth={500}
      >
        <BoardSetting
          handleClose={closeBoardSettings}
          deleteBoardAlertHandle={deleteBoardAlertHandle}
          showToastMessage={showToastMessage}
          currentBoardId={boardId}
          boardName={boardName}
          refetchBsiCoLab={refetch}
        />
      </Modals>
      <Modals
        open={openDeleteBoardAlert}
        handleClose={closeDeleteBoardAlert}
        modalWidth={500}
        boardId={boardId}
        boardName={boardName}
      >
        <DeleteBoardAlert
          handleClose={closeDeleteBoardAlert}
          goBackFromAlertHandle={goBackFromAlertHandle}
          showToastMessage={showToastMessage}
          refetch={refetch}
          currentBoardId={boardId}
          deleteTitle={"Board"}
        />
      </Modals>
    </>
  );
};

export default BsiCoLab;
